export default {
  title: 'Apply pricing template',
  forPerformer: 'for %{performer}',
  searchPlaceholder: 'Event name, performer',
  applyTemplateTooltip: 'Apply pricing template',
  marketComparables: 'Market Comparables',
  selectEventsToApplyTemplate: 'Select matching events to apply pricing template',
  selectTicketsToApplyTemplate: 'Select ticket groups to apply pricing template',
  applyToCreate: 'Apply to create pricing groups using the pricing template:',
  pricingTemplateApplied: 'Pricing template successfully applied.',
  pricingGroupsCreatedOfName:
    'Pricing group %{name} was successfully created in %{finished} of %{total} events in Simulation Only.',
  applyingTemplate: 'Applying template: %{name}',
  applyProgress: 'Progressing: %{done} of %{total}...',
  failedCreation: '%{failed} of %{total} failed',
  failedCreationCanceled: '%{canceled} out of %{total} events failed due to a canceled action',
  failedDetails:
    '%{name} failed to apply. Please review event data, ensure template compatibility, and review connectivity.',
  venue: 'Venue',
  simulationOnly: 'Simulation only',
  selectAll: 'Select all events',
  removeSelection: 'Remove selection',
  notAllowedForTemplate: 'Not allowed for template',
  selectedProductions: '%{num} selected',
  selectedTicketGroup: 'Select this ticket group',
  selectedEvent: 'Select event',
  removeTicketGroup: 'Remove selection',
  selectedProductionsToolTip: '%{num} selected ticket groups',
  showUnavailableProductions: 'Show unavailable productions',
  showUnavailableProductionsToolTip: 'Show productions in the same venue',
  ticketGroupCount: {
    one: '1 ticket group',
    other: '%{count} ticket groups',
  },
  warning: {
    title: 'Stay on this page while creating pricing groups.',
    body: 'Closing or leaving the page will stop the process.',
  },
  columns: {
    name: 'Event Name',
    productionDate: 'Event Date',
    myListing: 'Tickets',
    myAvgPrice: 'Avg Price (USD)',
    sold: 'Tickets sold',
    percentSold: '% of tickets',

    marketListing: 'Market tickets',
    marketAverage: 'Market Avg (USD)',
    eventId: 'Event ID',
    tbd: 'Time to event',
  },
  progress: {
    selectEvents: 'Select events',
    selectTicketGroups: 'Select ticket groups',
    reviewAndApply: 'Review and apply',
  },
  productionTicketsTable: {
    columns: {
      productionId: 'productionId',
      rank: 'Rank',
      ticketGroup: 'Ticket Group',
      section: 'Section',
      row: 'Row',
      quantity: 'Qty',
      seats: 'Seats',
      share: 'Sh',
      ticketDetail: 'Ticket Detail',
      stock: 'Stock',
      status: 'Status',
      cost: 'Cost (USD)',
      posPrice: 'POS Price (USD)',
      suggested: 'Suggested (USD)',
      listPrice: 'List Price',
      threshold: 'Threshold',
      ceilingScpFloor: 'ceiling/scp/floor',
      pricingGroup: 'Pricing Group',
      shared: 'Shared',
      change: 'Change',
    },
    sold: 'Sold',
    showUnavailableTickets: 'Show unavailable tickets',
    showUnavailableTicketsToolTip: 'Show tickets sold or already belong to other pricing group',
    unavailableTickets: 'Unavailable tickets',
    noTickets: 'No matching tickets',
    removeSelection: 'Remove selection',
    selectAll: 'Select all events',
    failedInventoryLoad: 'Failed to load inventory',
    failedPricingDataLoad: 'Failed to load pricing data',
  },
  cancelModal: {
    title: 'Stop applying template?',
    statement: 'This will stop creating pricing groups',
    description:
      'Pricing groups not started will be canceled, and the template will not be applied to the remaining groups.',
    confirmCTA: 'Proceed',
  },
  tooltip: {
    noComps: 'No comps',
  },
};
