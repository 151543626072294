import type {
  Action,
  ProductionMapData,
  HoveredSectionAction,
  JSONMap,
  MapState,
  MapTypes,
  MouseAction,
  PathSVG,
  TextSVG,
  SeatMapData,
} from './type';
import { ActionType } from './type';

interface SvgData {
  path: PathSVG[];
  text: TextSVG[];
}

export const getTransform = (transform = ''): string => {
  const transformSplit = transform.split('m');
  return transform ? `matrix(${transformSplit[1]})` : transform;
};

export const translateJsonMapByType = (jsonMap?: JSONMap) => {
  if (!jsonMap) return;
  const initSvgData: SvgData = { path: [], text: [] };
  const { elements, width, height } = jsonMap;
  const mapByType =
    elements &&
    elements.reduce<MapTypes>((acc: SvgData, item) => {
      const { type } = item;
      if (type === 'text') {
        acc.text.push(item);
      } else if (type === 'path') {
        acc.path.push(item);
      }
      return acc;
    }, initSvgData);
  return { mapByType, width, height };
};

export const extractSeatmapData = (eventData: ProductionMapData | undefined): SeatMapData | undefined => {
  if (!eventData) return;

  const isDataValid = Object.values(eventData).some((data) => !!data);
  if (!isDataValid) return;

  const { global, groups, sections } = eventData;
  const { jsonFileName, jsonActive } = global ?? {};

  return { jsonFileName, jsonActive, groups, sections };
};

export const extractSectionWithNoTicketGroups = (eventData: SeatMapData | undefined): string[] => {
  if (!eventData) return [];
  const { sections } = eventData;
  return sections?.filter((section) => section?.quantity === 0).map((section) => section.name);
};

export const setHoveredSectionId = (dispatch: React.Dispatch<HoveredSectionAction>, payload: string): void => {
  dispatch({ type: ActionType.SET_HOVERED_SECTION_ID, payload });
};
export const setIsMouseDown = (dispatch: React.Dispatch<MouseAction>, payload: boolean): void => {
  dispatch({ type: ActionType.SET_MOUSE_DOWN, payload });
};
export const setIsDragging = (dispatch: React.Dispatch<MouseAction>, payload: boolean): void => {
  dispatch({ type: ActionType.SET_DRAGGING, payload });
};

export const reducer = (mapState: MapState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_HOVERED_SECTION_ID:
      return { ...mapState, hoveredSectionId: action.payload };
    case ActionType.SET_MOUSE_DOWN:
      return { ...mapState, isMouseDown: action.payload };
    case ActionType.SET_DRAGGING:
      return { ...mapState, isDragging: action.payload };
    case ActionType.SET_DOUBLE_CLICK:
      return { ...mapState, isDoubleClicked: action.payload };
    default:
      return mapState;
  }
};

export const processPathSvgDataWithEventData = (
  pathSvgData: Array<PathSVG>,
  eventData: ProductionMapData | undefined,
) => {
  return pathSvgData
    .map((item) => {
      if (!item.id) {
        return item;
      }

      const sectionId = parseInt(item.id);
      const section = eventData?.sections?.find((section) => section.id === sectionId);
      const sectionName = section?.name;
      const group = section ? eventData?.groups.find((group) => group.id === section.groupId) : { color: '' };
      const fillColor = section?.quantity ? group?.color : item.fill;
      return { ...item, fill: fillColor, sectionName: sectionName };
    })
    .sort((a: PathSVG, b: PathSVG) => {
      if (!a.id && b.id) return -1;
      else if (a.id && !b.id) return 1;
      return 0;
    });
};
