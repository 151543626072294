import type {
  HitCeilingPricingMethod,
  HitFloorPricingMethod,
  HitNoCompPricingMethod,
  OrganizationPricingDefaultDto,
  PricingGroupRequest,
} from '@/api/pricer/pricerApiSchemas';
import type { IDataField, TField } from '@/interfaces/CommonInterfaces';
import {
  adjustments,
  customSharingUnits,
  groupAdjustments,
  initialInputValue,
  units,
  zeroInputValue,
} from './constants';
import { CustomSharingMethod } from '@/utils/constants';
import { groupShareOptions, PricingGroupRequestType } from '@/utils/constants';
import InputValidations from '@/utils/InputValidations';
import {
  HitFloorPricingMethodKeys,
  HitCeilingPricingMethodKeys,
  HitNoCompPricingMethodKeys,
} from '@/api/pricer/pricerApiSchemaExtensions';
import i18n from '@/utils/i18n';
import type { TextInputColor } from '@/components/common/TextInput';

const initialPushOptions = [
  {
    name: i18n.t('pricing.pushOptions.autoPrice').toUpperCase(),
    key: PricingGroupRequestType.AUTO_PRICE,
    disabled: false,
  },
  {
    name: i18n.t('pricing.pushOptions.simulation').toUpperCase(),
    key: PricingGroupRequestType.SIMULATION_ONLY,
    disabled: false,
  },
];

export const INITIAL_STATE: {
  adjustment: IDataField;
  pushOption: IDataField;
  unit: IDataField;
  adjustmentAmount: TField;
  floor: TField;
  ceiling: TField;
  floorApplyToAnchor: boolean;
  ceilingApplyToAnchor: boolean;
  lastEditedField: FieldType | undefined;
  groupAdjustment: IDataField;
  groupUnit: IDataField;
  groupAdjustmentAmount: TField;
  isSharingActive: boolean;
  isAnchorShared: boolean;
  groupShareOption: IDataField;
  shareTicketGroupsUnit: IDataField;
  shareTicketGroupsAmount: TField;
  name: TField;
  pricingGroupNameInputDisabled: boolean;
  isDirty: boolean;
  isFormValidated: boolean;
  scpValue: number | undefined;
  floorPricingMethod: HitFloorPricingMethod;
  ceilingPricingMethod: HitCeilingPricingMethod;
  noCompPricingMethod: HitNoCompPricingMethod;
  saveAsPricingTemplate: boolean;
  isDisabledOffsetAmount: boolean;
  isCeilingRequired: boolean;
  validationError?: string;
  isNoCompPricingMethodInvalid: boolean;
  ceilingOverwrittenByUser?: boolean;
  floorOverwrittenByUser?: boolean;
  allPushOptions: IDataField[];
} = {
  adjustment: adjustments[1],
  pushOption: initialPushOptions[0],
  unit: units[0],
  adjustmentAmount: initialInputValue,
  floor: initialInputValue,
  ceiling: initialInputValue,
  floorApplyToAnchor: false,
  ceilingApplyToAnchor: false,
  lastEditedField: undefined,
  groupAdjustment: groupAdjustments[1],
  groupUnit: units[0],
  groupAdjustmentAmount: zeroInputValue,
  groupShareOption: groupShareOptions[0],
  shareTicketGroupsUnit: customSharingUnits[0],
  shareTicketGroupsAmount: zeroInputValue,
  name: initialInputValue,
  pricingGroupNameInputDisabled: false,
  isSharingActive: true,
  isAnchorShared: true,
  isDirty: false,
  isFormValidated: true,
  scpValue: undefined,
  floorPricingMethod: HitFloorPricingMethodKeys.UseFloor,
  ceilingPricingMethod: HitCeilingPricingMethodKeys.UseCeiling,
  noCompPricingMethod: HitNoCompPricingMethodKeys.FreezePricing,
  saveAsPricingTemplate: false,
  isDisabledOffsetAmount: false,
  isCeilingRequired: false,
  validationError: '',
  isNoCompPricingMethodInvalid: false,
  ceilingOverwrittenByUser: false,
  floorOverwrittenByUser: false,
  allPushOptions: initialPushOptions,
};

export type InventoryPricingState = typeof INITIAL_STATE;

export enum FieldType {
  ADJUSTMENT = 'adjustment',
  ADJUSTMENT_AMOUNT = 'adjustmentAmount',
  FLOOR = 'floor',
  CEILING = 'ceiling',
  NAME = 'name',
  GROUP_ADJUSTMENT = 'groupAdjustment',
  GROUP_ADJUSTMENT_AMOUNT = 'groupAdjustmentAmount',
  SHARE_TG_AMOUNT = 'shareTicketGroupsAmount',
}

export const enum REDUCER_ACTION_TYPE {
  SET_INPUT_VALIDATIONS,
  CHANGE_INPUT,
  VALIDATE_INPUT,
  CHANGE_NAME_INPUT,
  VALIDATE_NAME_INPUT,
  SET_ADJUSTMENT,
  SET_UNIT,
  SET_PUSH_OPTION,
  SET_GROUP_ADJUSTMENT,
  SET_GROUP_UNIT,
  SET_SHARE_OPTION,
  TG_SHARING_ACTIVE,
  SET_SHARE_TG_UNIT,
  TG_ANCHOR_SHARING,
  LOAD_STATE_FROM_PRICING_GROUP,
  LOAD_STATE_FROM_METADATA,
  LOAD_STATE_FROM_ORG_DEFAULTS,
  SET_SCP_VALUE,
  RESET,
  SET_PRICING_METHOD_FLOOR,
  SET_PRICING_METHOD_CEILING,
  SET_PRICING_METHOD_NO_COMP,
  SAVE_AS_PRICING_TEMPLATE,
  TG_NO_COMP_ACTION,
  CHANGE_SHARE_TG_AMOUNT_INPUT,
  VALIDATE_SHARE_TG_AMOUNT_INPUT,
  RESET_OFFSET_INPUTS,
  RESET_FLOOR_CEILING_INPUTS,
  RESET_GROUP_OFFSET_INPUTS,
  RESET_SHARE_INPUTS,
  RESET_PUSH_INPUT,
  RESET_PRICING_METHODS,
  FLOOR_APPLY_TO_ANCHOR,
  CEILING_APPLY_TO_ANCHOR,
  SET_DIRTY,
}

type InputAction = {
  type: REDUCER_ACTION_TYPE.CHANGE_INPUT | REDUCER_ACTION_TYPE.VALIDATE_INPUT;
  payload: { name: string; value: string };
};

type InputValidationAction = {
  type: REDUCER_ACTION_TYPE.SET_INPUT_VALIDATIONS;
  payload: {
    [key: string]: { value: string; valid: boolean };
  };
};

type SetDataFieldAction = {
  type:
    | REDUCER_ACTION_TYPE.SET_PUSH_OPTION
    | REDUCER_ACTION_TYPE.SET_SHARE_OPTION
    | REDUCER_ACTION_TYPE.SET_SHARE_TG_UNIT
    | REDUCER_ACTION_TYPE.SET_ADJUSTMENT
    | REDUCER_ACTION_TYPE.SET_UNIT
    | REDUCER_ACTION_TYPE.SET_GROUP_ADJUSTMENT
    | REDUCER_ACTION_TYPE.SET_GROUP_UNIT;
  payload: IDataField;
};

type SetPricingMethodFloorAction = {
  type: REDUCER_ACTION_TYPE.SET_PRICING_METHOD_FLOOR;
  payload: HitFloorPricingMethod;
};

type SetPricingMethodCeilingAction = {
  type: REDUCER_ACTION_TYPE.SET_PRICING_METHOD_CEILING;
  payload: HitCeilingPricingMethod;
};

type SetPricingMethodNoCompAction = {
  type: REDUCER_ACTION_TYPE.SET_PRICING_METHOD_NO_COMP;
  payload: HitNoCompPricingMethod;
};

type ResetPricingMethodsAction = {
  type: REDUCER_ACTION_TYPE.RESET_PRICING_METHODS;
  payload: {
    floorPricingMethod: HitFloorPricingMethod;
    ceilingPricingMethod: HitCeilingPricingMethod;
    noCompPricingMethod: HitNoCompPricingMethod;
  };
};

type SimpleAction = {
  type:
    | REDUCER_ACTION_TYPE.RESET
    | REDUCER_ACTION_TYPE.TG_SHARING_ACTIVE
    | REDUCER_ACTION_TYPE.SAVE_AS_PRICING_TEMPLATE
    | REDUCER_ACTION_TYPE.TG_ANCHOR_SHARING
    | REDUCER_ACTION_TYPE.TG_NO_COMP_ACTION;
  payload?: any; //ToDo - remove any
};

type ScpActionAndOneTimeUpdated = {
  type: REDUCER_ACTION_TYPE.SET_SCP_VALUE;
  payload: {
    scp: number | undefined;
    isCreatePG: boolean;
  };
};

type SimplePayload = {
  type:
    | REDUCER_ACTION_TYPE.CHANGE_SHARE_TG_AMOUNT_INPUT
    | REDUCER_ACTION_TYPE.VALIDATE_SHARE_TG_AMOUNT_INPUT
    | REDUCER_ACTION_TYPE.CHANGE_NAME_INPUT
    | REDUCER_ACTION_TYPE.VALIDATE_NAME_INPUT;
  payload: string;
};

type BooleanPayload = {
  type:
    | REDUCER_ACTION_TYPE.FLOOR_APPLY_TO_ANCHOR
    | REDUCER_ACTION_TYPE.CEILING_APPLY_TO_ANCHOR
    | REDUCER_ACTION_TYPE.SET_DIRTY;
  payload: boolean;
};

type StateLoadAction = {
  type:
    | REDUCER_ACTION_TYPE.LOAD_STATE_FROM_PRICING_GROUP
    | REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS
    | REDUCER_ACTION_TYPE.LOAD_STATE_FROM_METADATA;
  payload: PricingGroupRequest | OrganizationPricingDefaultDto;
};

type ResetOrgDefaultsAction = {
  type:
    | REDUCER_ACTION_TYPE.RESET_OFFSET_INPUTS
    | REDUCER_ACTION_TYPE.RESET_GROUP_OFFSET_INPUTS
    | REDUCER_ACTION_TYPE.RESET_SHARE_INPUTS
    | REDUCER_ACTION_TYPE.RESET_PUSH_INPUT;
  payload: OrganizationPricingDefaultDto;
};

type ResetFloorCeilingInput = {
  type: REDUCER_ACTION_TYPE.RESET_FLOOR_CEILING_INPUTS;
  payload: { orgDefaults: OrganizationPricingDefaultDto; floorPercentage?: string; ceilingPercentage?: string };
};

export type ReducerAction =
  | InputAction
  | InputValidationAction
  | SimpleAction
  | SimplePayload
  | BooleanPayload
  | SetDataFieldAction
  | StateLoadAction
  | ResetOrgDefaultsAction
  | ResetFloorCeilingInput
  | ScpActionAndOneTimeUpdated
  | SetPricingMethodFloorAction
  | SetPricingMethodCeilingAction
  | SetPricingMethodNoCompAction
  | ResetPricingMethodsAction;

export const getValidatedShareInput = (
  shareValue: string,
  shareUnit: CustomSharingMethod,
  validateEmpty: boolean = true,
) => {
  if (shareValue === '') return { value: '', valid: validateEmpty ? false : undefined };

  const value = shareUnit === CustomSharingMethod.PERCENTAGE ? shareValue : parseInt(shareValue).toString();
  return {
    value: value,
    valid: InputValidations.isNumberWithDecimalOrScientific(value),
  };
};

export const getValidatedInput = (name: string, value: string): TField => {
  let valid;
  switch (name) {
    case FieldType.NAME:
      valid = value !== '' && InputValidations.isPricingGroupNameValid(value);
      break;
    case FieldType.ADJUSTMENT_AMOUNT:
    case FieldType.GROUP_ADJUSTMENT_AMOUNT:
      valid = value !== '' && InputValidations.isNumberWithDecimalOrScientific(value);
      break;
    case FieldType.CEILING:
      valid = value !== '' && InputValidations.isNumberWithDecimalOrScientific(value);
      break;
    case FieldType.FLOOR:
      valid = value !== '' && !!Number(value) && InputValidations.isNumberWithDecimalOrScientific(value);
  }
  return { value, valid };
};

export const getIsFloorBellowCeiling = ({ floor, ceiling }: { floor: TField; ceiling: TField }): boolean => {
  return !ceiling.valid || !floor.valid || Number(floor.value) < Number(ceiling.value);
};

export function IsFloorBelowCost(floor: number | undefined | null, costPrice: number | undefined | null) {
  return !!floor && floor < (costPrice || 0);
}

export function IsSuggestedBelowCost(suggestedPrice: number | undefined | null, costPrice: number | undefined | null) {
  return !!suggestedPrice && suggestedPrice <= (costPrice || 0);
}

export function getFloorTooltip(
  floorValidity: boolean | undefined,
  isFloorAboveCeiling: boolean | undefined,
  isFloorBellowCost: boolean,
  isFloorHit: boolean | undefined,
): string {
  if (floorValidity === false) return i18n.t('pricing.errors.floorRequired');
  if (isFloorAboveCeiling) return i18n.t('pricing.errors.floorAboveCeiling');
  if (isFloorHit) return i18n.t('inventory.tooltip.suggestedHitFloor');
  if (isFloorBellowCost) return i18n.t('pricing.errors.floorBellowCost');
  return i18n.t('pricing.labels.floorPrice');
}

export function getFloorColor(
  floorValidity: boolean | undefined,
  isFloorAboveCeiling: boolean | undefined,
  isFloorBellowCost: boolean,
  isFloorHit: boolean | undefined,
): TextInputColor {
  if (isFloorAboveCeiling || floorValidity === false) return 'error';
  if (isFloorBellowCost || isFloorHit) return 'warning';
  return 'main';
}

export function getCeilingTooltip(
  isCeilingMissing: boolean,
  isCeilingRequiredByDefault: boolean,
  isCeilingRequiredByNoHitComp: boolean,
  isCeilingBellowFloor: boolean | undefined,
  isCeilingHit: boolean | undefined,
): string {
  if (isCeilingMissing) {
    if (isCeilingRequiredByDefault) return i18n.t('pricing.errors.ceilingRequired');
    if (isCeilingRequiredByNoHitComp) return i18n.t('pricing.errors.ceilingRequiredByNoComp');
  }
  if (isCeilingBellowFloor) return i18n.t('pricing.errors.ceilingBellowFloor');
  if (isCeilingHit) return i18n.t('inventory.tooltip.suggestedHitCeiling');
  return i18n.t('pricing.labels.ceilingPrice');
}

export function getCeilingColor(
  isCeilingMissing: boolean,
  isCeilingBellowFloor: boolean | undefined,
  isCeilingHit: boolean | undefined,
): TextInputColor {
  if (isCeilingMissing || isCeilingBellowFloor) return 'error';
  if (isCeilingHit) return 'warning';
  return 'main';
}
