export default {
  notifications: {
    applying: {
      title: '',
      message: 'Saving organization pricing defaults.',
    },
    success: {
      title: '',
      message: 'Successfully saved organization pricing defaults.',
    },
    error: {
      title: '',
      message: 'Failed to save organization pricing defaults.',
    },
  },
  infoMessage:
    'Set the organization-wide market comparables defaults that helps all organization members quickly work on pricing. Any changes to pricing defaults will be applied to new pricing groups created afterwards. To reset back to the system defaults, select “Restore System Defaults”',
  labels: {
    restore: 'Restore System Defaults',
    inputToolTip: 'Enter numbers only',
  },
  comparableOptions: {
    title: 'Comparable options',
    splitsDefault: {
      title: 'Split value default',
      message:
        'System default is disabled. When enabled, pricing group’s split values will be selected one-time based on the anchor ticket group’s allowable splits.',
      toggleMessage: 'Continuously monitor and dynamically update pricing group’s split values ',
    },
    sectionDefault: {
      title: 'Section default',
      message:
        'System default is disabled. When enabled, the entire zone will be selected that the anchor ticket group section is in.',
    },
    rowDefault: {
      title: 'Row Range default',
      message:
        'System default is disabled. When enabled, the row range will be set to the provided number of rows (up to 100) in-front and behind the anchor ticket group.',
      rowInFrontLabel: 'Include rows in front',
      rowBehindLabel: 'Include rows behind',
      tooltip: 'Enter numbers only',
      error: {
        empty: 'Required fields',
        exceed: 'Exceeds maximum rows',
      },
    },
  },
};
