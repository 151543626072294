import {
  useMainSliceInventoryActions,
  useMainSliceInventoryStore,
  useSelectionSliceInventoryActions,
  useSelectionSliceInventoryStore,
} from '@/stores/inventory/inventory-store';
import { usePricerState } from '@/context/pricer/context-hooks';
import { useEffect, useMemo } from 'react';
import {
  getComparablesFromFilters,
  populateMarketFilters,
  populateMarketFiltersFromPG,
} from '@/context/pricing-mode/filter-constants';
import { AnchorRank, getPricingGroupsMap, isPricingGroupResponse } from '@/components/inventory/table/constants';
import type { IAnchorTicketGroupModel } from '@/components/inventory/table/IAnchorTicketGroupModel';
import type { InventoryResponseDtoV2, PricingGroupRequest, PricingGroupResponse } from '@/api/pricer/pricerApiSchemas';
import { generateFilters, updateRanksInInventoryAfterPricingGroupUpdate } from '@/context/inventory/constants';
import { CREATE_PRICING_GROUP_ID, getEmptyArray } from '@/utils/constants';
import { InventoryStatusKeys } from '@/api/pricer/pricerApiSchemaExtensions';
import useMarketplacePropertiesQuery from '@/hooks/inventory/queries/useMarketplacePropertiesQuery';
import useFetchPricingGroupsQuery from '@/hooks/inventory/queries/useFetchPricingGroupsQuery';
import useCalculatedPriceQuery from '@/hooks/inventory/queries/useCalculatedPriceQuery';
import useValidateComparablesQuery from '@/hooks/inventory/queries/useValidateComparablesQuery';
import useInventoryPropertiesQuery from '@/hooks/inventory/queries/useInventoryPropertiesQuery';
import useGetInventoryResponseQuery from '@/hooks/inventory/queries/useGetInventoryResponseQuery';
import { IsFloorBelowCost, IsSuggestedBelowCost } from '@/context/pricing-mode/reducer-constants';
import { useOrganizationContext } from '@/context/organization/context-hooks';

/**
 * This hook is used to manage the effects of the inventory store. It gets called ones
 */
function useInventoryStoreEffects() {
  const marketplacePropertiesQuery = useMarketplacePropertiesQuery();
  const pricingGroups = useFetchPricingGroupsQuery().pricingGroups;
  const calculatedPrice = useCalculatedPriceQuery().calculatedPrice;

  const activePricingGroup = useMainSliceInventoryStore('activePricingGroup');
  const marketFilters = useMainSliceInventoryStore('marketFilters');
  const mapSections = useMainSliceInventoryStore('mapSections');
  const mapZones = useMainSliceInventoryStore('mapZones');

  const { tgAttributes } = usePricerState();
  const { setMarketFilters } = useMainSliceInventoryActions();
  const { orgDefaults } = useOrganizationContext();

  // effects based on data from BE and store
  useEffect(() => {
    if (!marketplacePropertiesQuery.marketplaceProperties || !tgAttributes) return;

    populateMarketFilters(
      marketFilters,
      setMarketFilters,
      marketplacePropertiesQuery.marketplaceProperties,
      mapSections,
      mapZones,
      tgAttributes,
    );
  }, [
    marketplacePropertiesQuery.marketplaceProperties,
    tgAttributes,
    mapZones,
    mapSections,
    orgDefaults?.marketCriteriaDefaults,
  ]);

  useEffect(() => {
    if (!activePricingGroup) return;
    if (!marketplacePropertiesQuery.marketplaceProperties) {
      console.warn(
        'cannot populate market filters, marketplaceProperties is not available. Disable pricing group edit until all data is available',
      );
      return;
    }

    const pgResponse = getPricingGroupsMap(pricingGroups).get(activePricingGroup.requestId ?? 0);
    const isNewPricingGroup = activePricingGroup.requestId === CREATE_PRICING_GROUP_ID;

    populateMarketFiltersFromPG(
      marketFilters,
      pgResponse,
      setMarketFilters,
      marketplacePropertiesQuery.marketplaceProperties,
      mapSections,
      tgAttributes,
      isNewPricingGroup,
      orgDefaults,
    );
  }, [activePricingGroup?.marketGroupCriteria]);

  // pricing groups effects
  const { setPricingGroupsMap, setPricingGroupsAnchors } = useMainSliceInventoryActions();
  const processedInventory = useMainSliceInventoryStore('processedInventory');
  const pricingGroupsMapBase = useMemo(() => getPricingGroupsMap(pricingGroups), [pricingGroups]);
  useEffect(() => {
    let result = null;
    if (activePricingGroup) {
      // Remove the active pricing group from the list of pricing groups, it is converted to pricingGroupRequest
      const filteredGroups = pricingGroups?.filter((group) => group.id !== activePricingGroup.requestId);
      // todo: memoize this
      const groupsMap = getPricingGroupsMap(filteredGroups);
      groupsMap.set(activePricingGroup.requestId!, activePricingGroup);
      result = groupsMap;
    } else {
      result = pricingGroupsMapBase;
    }
    setPricingGroupsMap(result);
  }, [pricingGroupsMapBase, activePricingGroup]);

  const pricingGroupsMap = useMainSliceInventoryStore('pricingGroupsMap');
  useEffect(() => {
    const result = new Map<number, IAnchorTicketGroupModel>();
    if (!processedInventory?.length) {
      setPricingGroupsAnchors(result);
      return;
    }

    for (const [key, pGroup] of pricingGroupsMap) {
      let anchorTicketId: number;
      let tgCount = 0;
      let isFloorHit = false;
      let isCeilingHit = false;
      let isNoActiveCompHit = false;
      let isSuggestedBelowCost = false;
      let isFloorBelowCost = false;

      if (isPricingGroupResponse('pricingGroupTickets', pGroup)) {
        const pResponse = pGroup as PricingGroupResponse;
        anchorTicketId =
          pResponse.pricingGroupTickets?.find((ticket) => ticket.rank === AnchorRank)?.ticketGroupId || 0;
        tgCount = pResponse.pricingGroupTickets?.length || 0;

        pResponse.pricingGroupTickets?.forEach((ticket) => {
          isFloorHit = isFloorHit || ticket.isFloorHit!;
          isCeilingHit = isCeilingHit || ticket.isCeilingHit!;
          isNoActiveCompHit = isNoActiveCompHit || ticket.isNoActiveCompHit!;

          const inventoryTicket = processedInventory.find((item) => item.ticketGroupId === ticket.ticketGroupId);
          if (IsSuggestedBelowCost(inventoryTicket?.suggestedPrice, inventoryTicket?.costPrice)) {
            isSuggestedBelowCost = true;
          }
          if (IsFloorBelowCost(activePricingGroup?.floorPrice, inventoryTicket?.costPrice)) {
            isFloorBelowCost = true;
          }
        });
      } else {
        const pgRequest = pGroup as PricingGroupRequest;
        anchorTicketId =
          pgRequest.pricingGroupRequestRanks?.find((ticket) => ticket.rank === AnchorRank)?.ticketGroupId || 0;
        tgCount = pgRequest.pricingGroupRequestRanks?.length || 0;

        if (
          activePricingGroup &&
          calculatedPrice?.pricingGroupRequestRankResults?.length &&
          processedInventory?.length
        ) {
          calculatedPrice?.pricingGroupRequestRankResults?.forEach((ticket) => {
            isFloorHit = isFloorHit || ticket.isFloorHit!;
            isCeilingHit = isCeilingHit || ticket.isCeilingHit!;
            isNoActiveCompHit = isNoActiveCompHit || ticket.isNoActiveCompHit!;

            const inventoryTicket = processedInventory.find((item) => item.ticketGroupId === ticket.ticketGroupId);
            if (IsSuggestedBelowCost(inventoryTicket?.suggestedPrice, inventoryTicket?.costPrice)) {
              isSuggestedBelowCost = true;
            }
            if (IsFloorBelowCost(activePricingGroup?.floorPrice, inventoryTicket?.costPrice)) {
              isFloorBelowCost = true;
            }
          });
        }
      }

      const anchor = processedInventory.find((item) => item.ticketGroupId === anchorTicketId);

      result.set(key, {
        anchor,
        tgCount,
        hasWarnings: isCeilingHit || isFloorHit || isNoActiveCompHit || isSuggestedBelowCost || isFloorBelowCost,
        isCeilingHit,
        isFloorBelowCost,
        isSuggestedBelowCost,
        isNoActiveCompHit,
        isFloorHit,
        isLoading: false,
      });
    }

    setPricingGroupsAnchors(result);
  }, [pricingGroupsMap, calculatedPrice, processedInventory]);

  const { setProcessedInventory } = useMainSliceInventoryActions();
  useEffect(() => {
    if (!activePricingGroup?.requestId) {
      return;
    }
    setProcessedInventory(
      updateRanksInInventoryAfterPricingGroupUpdate(activePricingGroup, processedInventory) || getEmptyArray(),
    );
  }, [activePricingGroup?.pricingGroupRequestRanks, activePricingGroup?.requestId]);

  const validateComparablesQuery = useValidateComparablesQuery();
  const { setHasOverlap } = useMainSliceInventoryActions();

  useEffect(() => {
    if (validateComparablesQuery.validatedComparables) {
      setHasOverlap(!!validateComparablesQuery.validatedComparables.hasOverlap);
    }
  }, [validateComparablesQuery.validatedComparables]);

  const selectedProduction = useMainSliceInventoryStore('selectedProduction');
  const inventoryFilters = useMainSliceInventoryStore('inventoryFilters');

  useEffect(() => {
    if (
      marketFilters.sections.data.options.filter((option) => option.selected).length > 0 &&
      marketFilters.rowRanges?.data.options.length !== 0
    ) {
      const comparables = getComparablesFromFilters(marketFilters);
      validateComparablesQuery.validateComparables({
        ...comparables,
        pricingGroupId: activePricingGroup?.requestId,
        productionId: selectedProduction?.productionId ?? 0,
      });
    } else {
      setHasOverlap(false);
    }
  }, [marketFilters.sections, marketFilters.rowRanges]);

  const calculatedPriceQuery = useCalculatedPriceQuery();
  useEffect(() => {
    if (!activePricingGroup?.requestId) {
      calculatedPriceQuery.resetCalculatedPrice();
    }
  }, [activePricingGroup?.requestId]);

  const inventoryPropertiesQuery = useInventoryPropertiesQuery();
  const { setInventoryFilters } = useMainSliceInventoryActions();
  useEffect(() => {
    if (inventoryPropertiesQuery.inventoryProperties) {
      setInventoryFilters(generateFilters(inventoryPropertiesQuery.inventoryProperties, inventoryFilters));
    }
  }, [inventoryPropertiesQuery.inventoryProperties]);

  // selection Mode effects
  const { markIsAllSelectedBasedOnUnassignedTickets } = useSelectionSliceInventoryActions();
  const countOfUnassignedTickets = useMemo(() => {
    return processedInventory?.filter(
      (item) =>
        !item.pricingGroupId && item.inventoryStatus?.toLowerCase() === InventoryStatusKeys.Available.toLowerCase(),
    ).length;
  }, [processedInventory]);

  const addedTicketsForNewPricingGroup = useSelectionSliceInventoryStore('addedTicketsForNewPricingGroup');
  useEffect(() => {
    markIsAllSelectedBasedOnUnassignedTickets(countOfUnassignedTickets);
  }, [countOfUnassignedTickets, addedTicketsForNewPricingGroup]);

  // set processed inventory
  const inventoryResponseQuery = useGetInventoryResponseQuery();
  useEffect(() => {
    setProcessedInventory(inventoryResponseQuery.inventoryResponseData ?? getEmptyArray<InventoryResponseDtoV2>());
  }, [inventoryResponseQuery.inventoryResponseData]);
}

export default useInventoryStoreEffects;
