import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import { cva } from 'class-variance-authority';

export type ToggleSize = 'sm' | 'md';

interface IProps {
  isChecked: boolean;
  handleSwitch: () => void;
  disabled?: boolean;
  size?: ToggleSize;
  label?: string;
  hotkey?: string;
  className?: string;
  labelClassName?: string;
}

function Toggle({ isChecked, handleSwitch, disabled, size = 'md', label, className, labelClassName, hotkey }: IProps) {
  return (
    <Switch.Group>
      <div className={clsx(styles.container, className)}>
        <div className={styles.content}>
          <Switch
            checked={isChecked}
            disabled={disabled}
            onChange={disabled ? () => {} : handleSwitch}
            className={switchStyleVariation({ size })}
          >
            <span aria-hidden="true" className={railStyleVariation({ size, isChecked })} />
            <span aria-hidden="true" className={thumbStyleVariation({ size, isChecked })} />
          </Switch>
          <Switch.Label
            className={clsx(
              'flex-1 select-none truncate text-sm',
              disabled ? 'text-txt-disabled' : 'text-txt-primary',
              labelClassName,
            )}
          >
            {label}
          </Switch.Label>
        </div>
        {hotkey && (
          <span className={'relative bottom-[3px] whitespace-nowrap text-2xs text-txt-disabled'}>{hotkey}</span>
        )}
      </div>
    </Switch.Group>
  );
}

const styles = {
  container: 'flex items-baseline justify-between gap-x-1',
  content: 'flex flex-1 items-center gap-x-2 truncate',
  thumbActiveSmall: 'translate-x-3 bg-primary-main',
  thumbActiveMedium: 'translate-x-5 bg-primary-main',
};

const switchStyleVariation = cva(
  'group relative inline-flex flex-shrink-0 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50 items-center justify-center rounded-full focus:outline-none',
  {
    variants: {
      size: {
        sm: 'h-4 w-6 mx-1',
        md: 'h-4 w-10',
      },
    },
  },
);

const railStyleVariation = cva(
  'group relative inline-flex flex-shrink-0 disabled:opacity-50 items-center justify-center rounded-full focus:outline-none',
  {
    variants: {
      size: {
        sm: 'h-3 w-6 ml-1',
        md: 'h-4 w-10',
      },
      isChecked: {
        true: 'bg-primary-main/50',
        false: 'bg-action-active',
      },
    },
  },
);

const thumbStyleVariation = cva(
  'pointer-events-none absolute left-0 inline-block transform rounded-full shadow-md ring-1 ring-divider transition-transform duration-200 ease-in-out',
  {
    variants: {
      size: {
        sm: 'h-4 w-4',
        md: 'h-5 w-5',
      },
      isChecked: {
        false: 'translate-x-0 bg-white',
      },
    },
    compoundVariants: [
      { size: 'sm', isChecked: true, className: 'translate-x-3 bg-primary-main' },
      { size: 'md', isChecked: true, className: 'translate-x-5 bg-primary-main' },
    ],
  },
);

export default Toggle;
