export interface Event {
  id: number;
  name: string;
  date: string;
  venue: Venue;
  performerId: number;
  performer: Performer;
  keywords: string;
  chartUrl: string;
  stubhubEventId: number;
  stubhubEventUrl: string;
  tags: string[] | null;
  notes: string | null;
  eiEventId: number;
  optOutReplenishment: boolean;
  ticketCount: null;
  mySoldTickets: number;
  myCancelledTickets: number;
  disabled: boolean;
  vividSeatsEventUrl: string;
}

export interface Performer {
  id: number;
  name: string;
  eventType: string;
  category?: Performer;
}

export interface Venue {
  id: number;
  name: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  phone: string;
  timeZone: string;
}

export interface ProductionMapGlobal {
  city: string;
  eventDate: string;
  productionName: string;
  jsonFileName: string;
  jsonActive: boolean;
  staticMapUrl: string;
  mapTitle: string;
  state: string;
  daysToEvent: number;
}

export interface Listing {
  skyBoxInventoryId: string | number | null;
  vividListingId: number;
  quantity: number;
  validQuantities: number[];
  groupId: number;
  sectionId: number;
  section: string;
  row: string;
  seats: string | null;
  unitCost: string | null;
  expectedValue: string | null;
  sellPrice: string | null;
  brokerPrice: string | null;
  unitPrice: number | null;
  pppUnitPrice: number | null;
  inHandDate: string | null;
  lastPriceUpdate: string | null;
  lastPriceUpdateBy: string | null;
  vendor: string | null;
  stockType: string | null;
  instantDownload: boolean | null;
  electronic: boolean | null;
  zone: boolean | null;
  publicNotes: string | null;
  notes: string | null;
  tags: string | null;
  broadcast: string | null;
  listed: boolean | null;
  seatType: string | null;
  lowSeat: number | null;
  highSeat: number | null;
  listPrice: string | null;
  unitCostAverage: string | null;
}

export interface ProductionSection {
  id: number;
  name: string;
  groupId: number;
  quantity: number;
  available: boolean;
  lowestPrice: number;
  highestPrice: number;
  seatViewLargeImage: string;
  seatViewSmallImage: string;
}

export interface ProductionMapGroup {
  id: number;
  name: string;
  quantity: number;
  available: boolean;
  lowestPrice: number;
  highestPrice: number;
  color: string;
}
export interface ProductionMapData {
  global: ProductionMapGlobal;
  groups: ProductionMapGroup[];
  sections: ProductionSection[];
}

export interface SeatMapData {
  jsonFileName: string;
  jsonActive: boolean;
  groups: ProductionMapGroup[];
  sections: ProductionSection[];
}

export interface TextSVG {
  stroke?: string;
  fill?: string;
  'font-size'?: string;
  'font-family'?: string;
  'font-weight'?: string;
  'text-anchor'?: string;
  text: string;
  type: 'text';
  transform?: string;
  x?: string;
  y?: string;
  id?: string;
}
export interface PathSVG {
  stroke?: string;
  fill?: string;
  path: string;
  'fill-opacity'?: string;
  opacity?: string;
  type: 'path';
  transform?: string;
  id?: string;
  sectionName?: string;
  dataTestId?: string;
}

export type SvgElement = TextSVG | PathSVG;

export interface SeatMapSvgData {
  height: string;
  width: string;
  elements: SvgElement[];
}

export interface JSONMap {
  height?: string;
  width?: string;
  elements: Array<TextSVG | PathSVG>;
}

export interface MapTypes {
  path: Array<PathSVG>;
  text: Array<TextSVG>;
}

export interface PathsContainerProps {
  paths: Array<PathSVG>;
}

export interface TextsContainerProps {
  texts: Array<TextSVG>;
}

export interface MapCallbacks {
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  onPointerDownCapture?: () => void;
  onPointerUpCapture?: () => void;
  onDoubleClick?: () => void;
}

export interface SimpleMapProps {
  jsonMap?: JSONMap;
  callbacks?: MapCallbacks;
  disableTouchEventListeners?: boolean;
  className?: string;
}

/**
 * map context
 */
export enum ActionType {
  SET_MIN_PRICE,
  SET_POS,
  SET_HOVERED_SECTION_ID,
  SET_MOUSE_DOWN,
  SET_DRAGGING,
  SET_DOUBLE_CLICK,
}

export interface MapState {
  minPrice?: {
    id: string;
    value: number;
  };
  position?: {
    x: number;
    y: number;
  };
  hoveredSectionId?: string;
  isMouseDown?: boolean;
  isDragging?: boolean;
  isDoubleClicked?: boolean;
}
export interface MapContextType {
  state: {
    data: SeatMapSvgData | undefined;
    isLoading: boolean;
    isError: boolean;
  };
  selectors: {
    isProductionMapDataLoading: boolean;
    mapSvgData: SeatMapSvgData | undefined;
    mapState: MapState;
  };
  dispatch: {
    setHoveredSectionId: (payload: string) => void;
    setIsMouseDown: (isDown: boolean) => void;
    setIsDragging: (isDragging: boolean) => void;
    setIsOpenMappingRequest: (isOpen: boolean) => void;
  };
}
export interface HoveredSectionAction {
  type: ActionType.SET_HOVERED_SECTION_ID;
  payload: string;
}

export interface MouseAction {
  type: ActionType.SET_DRAGGING | ActionType.SET_DOUBLE_CLICK | ActionType.SET_MOUSE_DOWN;
  payload: boolean;
}

export type Action = HoveredSectionAction | MouseAction;

export interface HoveredSectionAction {
  type: ActionType.SET_HOVERED_SECTION_ID;
  payload: string;
}

export interface MouseAction {
  type: ActionType.SET_DRAGGING | ActionType.SET_DOUBLE_CLICK | ActionType.SET_MOUSE_DOWN;
  payload: boolean;
}

export interface RuleFormValues {
  compSections?: Array<string>;
}
