export default {
  titleActiveMarket: 'Active Market',
  titleComparablesDrive: 'Vivid Seats Comparables',
  titleComparables: 'Active Listings',
  tabs: {
    active: 'Active Market',
    sales: 'Sales',
  },
  filters: {
    splits: 'Splits',
    deliveryMethod: 'Delivery Method',
    deliveryMethodTooltip: 'Select delivery method',
    excludeAttributes: 'Exclude Attributes',
    excludeAttributesTooltip: 'Select attributes to exclude',
    section: 'Section',
    sections: 'Sections',
    sectionsTooltip: 'Select sections',
    options: 'Options',
    splitType: 'Split Type',
    splitRule: 'Split Rule',
    seatType: 'Seat Type',
    stockType: 'Stock Type',
    attributes: 'Exclude Attributes',
    status: 'Status',
    pricingCriteria: 'Pricing Criteria',
    recentSearches: 'Recent Searches',
    zones: 'Zones',
    zonesTooltip: 'Select zones',
    seatSaver: 'Seat saver',
    speculation: 'Speculation',
    lowerOutlier: 'Exclude',
    lowerOutlierAmount: 'Amount',
    unit: {
      Currency: 'Exclude listings below price',
      Quantity: 'Number of listings to exclude',
      Percentage: 'Exclude percentage of lowest listings',
    },
  },
  validSplits: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8+',
  },
  rowRange: {
    label: 'Row range',
    placeholder: 'Row range i.e. 2;5',
    toolTip: 'Use a semicolon ";" to indicate a range i.e 2;5',
  },
  moreFilters: 'More filters',
  activeMarket: {
    myListingsToggle: 'Mine (%{count})',
    outliersToggle: 'Outliers (%{count})',
    quantity: 'Displaying %{filtered} of %{total}',
    myTicketGroup: 'My ticket group',
    outlierExcluded: 'Outlier excluded from suggested criteria price.',
  },
  includeSeatSaver: {
    title: 'Include Seat Saver',
    affirmative: 'Yes',
    negative: 'No',
  },
  excludeOutliers: {
    title: 'Exclude outliers',
    textLabel: 'Exclude lowest listings',
    textPlaceholder: 'i.e 5',
    toggleLabel: 'Show in active market list',
    decrementTooltip: 'Decrease',
    incrementTooltip: 'Increase',
  },
  excludeOutliersMethod: {
    title: 'Exclude outliers method',
    textLabel: 'Exclude lowest listings method',
    textPlaceholder: 'Quantity, Currency or Percentage',
    toggleLabel: 'Show in active market list',
  },
  columns: {
    delivery: 'Delivery',
    method: 'Method',
  },
  sectionSearch: {
    toggle: 'Only map sections',
    placeholder: 'Search sections',
  },
  rowSearch: {
    rowSeqHint: 'Enter rows with comma separated in sequential order.',
    warning: {
      lineOne: 'We are unable to interpret row sequence as provided in Row range from-to.',
      lineTwo: 'Enter entire rows with comma separated in sequential order. (i.e aa,bb,cc,zz)',
    },
    rowRange: 'Row range',
    rowSequence: 'Row sequence',
    rowSequencePlaceholder: 'Enter rows',
  },
  hasOverlap: {
    message: 'There is an existing pricing group with overlapping sections and rows.',
  },
};
