import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { AppModeIsDrive, NavLocationType } from '@/utils/constants';
import type { SideBarMenuItem } from '@/interfaces/CommonInterfaces';
import { Link, NavLink } from 'react-router-dom';
import { QuickLinks } from '@/components/sidebar/quick-links';
import NavDelimiter from '@/components/NavigationMenu/NavDelimiter';
import { useUserState } from '@/context/user/context-hooks';
import { predefinedRoutes } from '@/nav/constants';
import { ChevronDoubleDownIcon } from '../icons';

interface iProps {
  home?: SideBarMenuItem[];
  pages: SideBarMenuItem[];
  systemPages?: SideBarMenuItem[];
}
function ExpandedMenu({ home, pages, systemPages }: iProps) {
  const [expandedMenuVisible, setExpandedMenuVisible] = useState(false);
  const { preferences } = useUserState();
  const isFloatingNavigation = preferences.navigation.isFloating;
  const navigationLocation = preferences.navigation.location;

  const isNavBarOnTop = navigationLocation === NavLocationType.TOP || !isFloatingNavigation;
  const isNavBarOnBottom = navigationLocation === NavLocationType.BOTTOM && isFloatingNavigation;
  const isNavBarOnLeft = navigationLocation === NavLocationType.LEFT && isFloatingNavigation;
  const isNavBarOnRight = navigationLocation === NavLocationType.RIGHT && isFloatingNavigation;
  const isNavBarHorizontal = isNavBarOnTop || isNavBarOnBottom;
  const isNavBarVertical = isNavBarOnLeft || isNavBarOnRight;

  const [systemPagesWithPos, setSystemPagesWithPos] = useState<SideBarMenuItem[] | undefined>(systemPages);
  useEffect(() => {
    if (systemPages && systemPages.includes(predefinedRoutes.companyInfo)) {
      setSystemPagesWithPos([...systemPages.concat(predefinedRoutes.pos)]);
    }
  }, [systemPages]);
  function renderExpandedMenuByGroups(items: SideBarMenuItem[] | undefined, close: () => void) {
    if (!items || items.length === 0) return;
    return (
      <div>
        <ul
          className={clsx('flex flex-col gap-1', {
            'sm:hidden': isNavBarHorizontal,
            'px-2 vlg:hidden': isNavBarVertical,
          })}
        >
          {items?.map((item: SideBarMenuItem) => (
            <li key={item.path}>
              <NavLink
                to={item.path}
                className={({ isActive }) => {
                  const isEventsIndexPage = location.pathname === '/' && item.path === predefinedRoutes.events.path;
                  const isCurrent = (isActive || isEventsIndexPage) && item.path === location.pathname;
                  return clsx('flex items-center gap-2 p-2 text-sm', {
                    [styles.menuActive]: isCurrent,
                  });
                }}
                onClick={() => close()}
              >
                {item.icon ? <item.icon className="h-5 w-6 text-txt-primary" /> : null}
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
        <NavDelimiter
          className={clsx({
            'sm:hidden': isNavBarHorizontal,
            hidden: isNavBarVertical,
          })}
          isVertical={false}
        />
      </div>
    );
  }

  function renderQuickLinksAsExtendedMenu(close: () => void) {
    return (
      <ul className="flex flex-col gap-1 whitespace-nowrap pr-8">
        {QuickLinks.filter((x) => !AppModeIsDrive || x.showInDrive).map((item) => (
          <li key={item.url}>
            <Link
              to={item.url}
              className="flex h-full w-fit items-center gap-2 p-2 text-sm"
              target="_blank"
              onClick={() => close()}
            >
              <img src={item.icon} className="h-5 w-5" alt={item.label} />
              <div className="w-fit">{item.label}</div>
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <Popover className="relative flex min-h-full items-center">
      <Popover.Button
        className=" hover:text-txt-secondary/50 focus:outline-none"
        onClick={() => setExpandedMenuVisible(!expandedMenuVisible)}
      >
        <ChevronDoubleDownIcon
          className={clsx(styles.expandMenuButton, {
            'md:hidden': isNavBarHorizontal,
            'vlg:hidden': isNavBarVertical,
            'rotate-180':
              (isNavBarOnTop && expandedMenuVisible) ||
              (isNavBarOnBottom && !expandedMenuVisible) ||
              (isNavBarOnLeft && !expandedMenuVisible) ||
              (isNavBarOnRight && expandedMenuVisible),
            'rotate-0': (isNavBarOnTop && !expandedMenuVisible) || (isNavBarOnBottom && expandedMenuVisible),
            'rotate-270': (isNavBarOnLeft && expandedMenuVisible) || (isNavBarOnRight && !expandedMenuVisible),
          })}
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        afterLeave={() => setExpandedMenuVisible(false)}
      >
        <Popover.Panel>
          {({ close }) => (
            <>
              <div
                className={clsx(styles.expandedMenuContainer, {
                  'w-52': isNavBarHorizontal,
                  'di w-fit whitespace-nowrap': isNavBarVertical,
                  'right-0': isNavBarOnTop,
                  'bottom-5 right-0': isNavBarOnBottom,
                  '-bottom-5 right-0  origin-bottom-right -rotate-90': isNavBarOnRight,
                  'bottom-5 left-0  origin-bottom-left -rotate-90': isNavBarOnLeft,
                })}
              >
                <div
                  className={clsx({
                    'flex flex-col': isNavBarHorizontal,
                    'flex flex-row': isNavBarVertical,
                    'divide-x divide-divider vlg:divide-none': isNavBarVertical,
                  })}
                >
                  {renderExpandedMenuByGroups(home, close)}
                  {renderExpandedMenuByGroups(pages, close)}
                  {!AppModeIsDrive && systemPagesWithPos?.length
                    ? renderExpandedMenuByGroups(systemPagesWithPos, close)
                    : null}

                  {renderQuickLinksAsExtendedMenu(close)}
                </div>
              </div>
            </>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

const styles = {
  menuActive: 'bg-gradient-to-r from-primary-main to-primary-alt-med',
  expandMenuButton: 'my-2 mr-2 h-5 w-5 origin-center transform text-txt-primary transition-all',
  expandedMenuContainer:
    'absolute z-10 mt-6 bg-paper-card py-1 rounded-md drop-shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none',
};

export default ExpandedMenu;
