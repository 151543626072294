import { lazy, Suspense } from 'react';
import PageLayout from '@/components/PageLayout';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from '@/components/common';
import { AuthService } from '@/services/AuthService';
const DashboardMfe = lazy(() => import('remoteApp/Dashboard') as any);

function Dashboard() {
  return (
    <PageLayout>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="flex h-full w-full flex-col items-center justify-center">
          <Suspense fallback={<div>Loading...</div>}>
            <DashboardMfe getAuthToken={() => AuthService.getAccessToken()} />
          </Suspense>
        </div>
      </ErrorBoundary>
    </PageLayout>
  );
}

export default Dashboard;
