/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from '@tanstack/react-query';
import { useClientServiceApiContext, ClientServiceApiContext } from './clientServiceApiContext';
import type * as Fetcher from './clientServiceApiFetcher';
import { clientServiceApiFetch } from './clientServiceApiFetcher';
import type * as Schemas from './clientServiceApiSchemas';

export type GetAdminUserClaimsError = Fetcher.ErrorWrapper<undefined>;

export type GetAdminUserClaimsVariables = {
  body?: Schemas.CustomPolicyRequestDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchGetAdminUserClaims = (variables: GetAdminUserClaimsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<
    Schemas.TokenUserInfoResponse,
    GetAdminUserClaimsError,
    Schemas.CustomPolicyRequestDto,
    {},
    {},
    {}
  >({
    url: '/api/AdminUsers/admin-user-claims',
    method: 'post',
    ...variables,
    signal,
  });

export const useGetAdminUserClaims = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.TokenUserInfoResponse, GetAdminUserClaimsError, GetAdminUserClaimsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<Schemas.TokenUserInfoResponse, GetAdminUserClaimsError, GetAdminUserClaimsVariables>({
    mutationFn: (variables: GetAdminUserClaimsVariables) =>
      fetchGetAdminUserClaims({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ActivateUserPathParams = {
  key: string;
};

export type ActivateUserError = Fetcher.ErrorWrapper<undefined>;

export type ActivateUserVariables = {
  pathParams: ActivateUserPathParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchActivateUser = (variables: ActivateUserVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, ActivateUserError, undefined, {}, {}, ActivateUserPathParams>({
    url: '/api/ClientUsersApp/{key}/activate',
    method: 'post',
    ...variables,
    signal,
  });

export const useActivateUser = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ActivateUserError, ActivateUserVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, ActivateUserError, ActivateUserVariables>({
    mutationFn: (variables: ActivateUserVariables) => fetchActivateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeactivateUserPathParams = {
  key: string;
};

export type DeactivateUserError = Fetcher.ErrorWrapper<undefined>;

export type DeactivateUserVariables = {
  pathParams: DeactivateUserPathParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchDeactivateUser = (variables: DeactivateUserVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, DeactivateUserError, undefined, {}, {}, DeactivateUserPathParams>({
    url: '/api/ClientUsersApp/{key}/deactivate',
    method: 'post',
    ...variables,
    signal,
  });

export const useDeactivateUser = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, DeactivateUserError, DeactivateUserVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, DeactivateUserError, DeactivateUserVariables>({
    mutationFn: (variables: DeactivateUserVariables) => fetchDeactivateUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ForceResetPasswordPathParams = {
  key: string;
};

export type ForceResetPasswordError = Fetcher.ErrorWrapper<undefined>;

export type ForceResetPasswordVariables = {
  pathParams: ForceResetPasswordPathParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchForceResetPassword = (variables: ForceResetPasswordVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, ForceResetPasswordError, undefined, {}, {}, ForceResetPasswordPathParams>({
    url: '/api/ClientUsersApp/ForceResetPassword/{key}',
    method: 'put',
    ...variables,
    signal,
  });

export const useForceResetPassword = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ForceResetPasswordError, ForceResetPasswordVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, ForceResetPasswordError, ForceResetPasswordVariables>({
    mutationFn: (variables: ForceResetPasswordVariables) =>
      fetchForceResetPassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetClientUserError = Fetcher.ErrorWrapper<undefined>;

export type GetClientUserResponse = Schemas.ClientUserAppResponse[];

export type GetClientUserVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetClientUser = (variables: GetClientUserVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<GetClientUserResponse, GetClientUserError, undefined, {}, {}, {}>({
    url: '/api/ClientUsersApp',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetClientUser = <TData = GetClientUserResponse>(
  variables: GetClientUserVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetClientUserResponse, GetClientUserError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<GetClientUserResponse, GetClientUserError, TData>({
    queryKey: queryKeyFn({
      path: '/api/ClientUsersApp',
      operationId: 'getClientUser',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetClientUser({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetMenuItemsError = Fetcher.ErrorWrapper<undefined>;

export type GetMenuItemsVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetMenuItems = (variables: GetMenuItemsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<Schemas.ClientUserAppMenuItemsResponse, GetMenuItemsError, undefined, {}, {}, {}>({
    url: '/api/ClientUsersApp/get-menu-items',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMenuItems = <TData = Schemas.ClientUserAppMenuItemsResponse>(
  variables: GetMenuItemsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientUserAppMenuItemsResponse, GetMenuItemsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<Schemas.ClientUserAppMenuItemsResponse, GetMenuItemsError, TData>({
    queryKey: queryKeyFn({
      path: '/api/ClientUsersApp/get-menu-items',
      operationId: 'getMenuItems',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetMenuItems({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetProfileError = Fetcher.ErrorWrapper<undefined>;

export type GetProfileVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetProfile = (variables: GetProfileVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<Schemas.ClientUserAppProfileResponse, GetProfileError, undefined, {}, {}, {}>({
    url: '/api/ClientUsersApp/profile',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetProfile = <TData = Schemas.ClientUserAppProfileResponse>(
  variables: GetProfileVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ClientUserAppProfileResponse, GetProfileError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<Schemas.ClientUserAppProfileResponse, GetProfileError, TData>({
    queryKey: queryKeyFn({
      path: '/api/ClientUsersApp/profile',
      operationId: 'getProfile',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetProfile({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutProfileError = Fetcher.ErrorWrapper<undefined>;

export type PutProfileVariables = {
  body?: Schemas.ClientUserAppProfileRequest;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchPutProfile = (variables: PutProfileVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<
    Schemas.ClientUserAppProfileResponse,
    PutProfileError,
    Schemas.ClientUserAppProfileRequest,
    {},
    {},
    {}
  >({
    url: '/api/ClientUsersApp/profile',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutProfile = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.ClientUserAppProfileResponse, PutProfileError, PutProfileVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<Schemas.ClientUserAppProfileResponse, PutProfileError, PutProfileVariables>({
    mutationFn: (variables: PutProfileVariables) => fetchPutProfile({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSkyboxUserClaimsError = Fetcher.ErrorWrapper<undefined>;

export type GetSkyboxUserClaimsVariables = {
  body?: Schemas.CustomPolicyRequestDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchGetSkyboxUserClaims = (variables: GetSkyboxUserClaimsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<
    Schemas.TokenUserInfoResponse,
    GetSkyboxUserClaimsError,
    Schemas.CustomPolicyRequestDto,
    {},
    {},
    {}
  >({
    url: '/api/ClientUsersApp/skybox-user-claims',
    method: 'post',
    ...variables,
    signal,
  });

export const useGetSkyboxUserClaims = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TokenUserInfoResponse,
      GetSkyboxUserClaimsError,
      GetSkyboxUserClaimsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<Schemas.TokenUserInfoResponse, GetSkyboxUserClaimsError, GetSkyboxUserClaimsVariables>({
    mutationFn: (variables: GetSkyboxUserClaimsVariables) =>
      fetchGetSkyboxUserClaims({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetSkyboxUserRoleError = Fetcher.ErrorWrapper<undefined>;

export type GetSkyboxUserRoleVariables = {
  body?: Schemas.CustomPolicyRequestDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchGetSkyboxUserRole = (variables: GetSkyboxUserRoleVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<string, GetSkyboxUserRoleError, Schemas.CustomPolicyRequestDto, {}, {}, {}>({
    url: '/api/ClientUsersApp/skybox-user-role',
    method: 'post',
    ...variables,
    signal,
  });

export const useGetSkyboxUserRole = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, GetSkyboxUserRoleError, GetSkyboxUserRoleVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<string, GetSkyboxUserRoleError, GetSkyboxUserRoleVariables>({
    mutationFn: (variables: GetSkyboxUserRoleVariables) => fetchGetSkyboxUserRole({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserClaimsError = Fetcher.ErrorWrapper<undefined>;

export type GetUserClaimsVariables = {
  body?: Schemas.CustomPolicyRequestDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchGetUserClaims = (variables: GetUserClaimsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<Schemas.TokenUserInfoResponse, GetUserClaimsError, Schemas.CustomPolicyRequestDto, {}, {}, {}>({
    url: '/api/ClientUsersApp/user-claims',
    method: 'post',
    ...variables,
    signal,
  });

export const useGetUserClaims = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.TokenUserInfoResponse, GetUserClaimsError, GetUserClaimsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<Schemas.TokenUserInfoResponse, GetUserClaimsError, GetUserClaimsVariables>({
    mutationFn: (variables: GetUserClaimsVariables) => fetchGetUserClaims({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserRoleError = Fetcher.ErrorWrapper<undefined>;

export type GetUserRoleVariables = {
  body?: Schemas.CustomPolicyRequestDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchGetUserRole = (variables: GetUserRoleVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<string, GetUserRoleError, Schemas.CustomPolicyRequestDto, {}, {}, {}>({
    url: '/api/ClientUsersApp/user-role',
    method: 'post',
    ...variables,
    signal,
  });

export const useGetUserRole = (
  options?: Omit<reactQuery.UseMutationOptions<string, GetUserRoleError, GetUserRoleVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<string, GetUserRoleError, GetUserRoleVariables>({
    mutationFn: (variables: GetUserRoleVariables) => fetchGetUserRole({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutClientUserPathParams = {
  key: string;
};

export type PutClientUserError = Fetcher.ErrorWrapper<undefined>;

export type PutClientUserVariables = {
  body?: Schemas.ClientUserAppRequest;
  pathParams: PutClientUserPathParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchPutClientUser = (variables: PutClientUserVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<
    Schemas.ClientUserAppResponse,
    PutClientUserError,
    Schemas.ClientUserAppRequest,
    {},
    {},
    PutClientUserPathParams
  >({ url: '/api/ClientUsersApp/{key}', method: 'put', ...variables, signal });

export const usePutClientUser = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.ClientUserAppResponse, PutClientUserError, PutClientUserVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<Schemas.ClientUserAppResponse, PutClientUserError, PutClientUserVariables>({
    mutationFn: (variables: PutClientUserVariables) => fetchPutClientUser({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ResetPasswordError = Fetcher.ErrorWrapper<undefined>;

export type ResetPasswordVariables = {
  body?: Schemas.ResetPasswordRequest;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchResetPassword = (variables: ResetPasswordVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<string, ResetPasswordError, Schemas.ResetPasswordRequest, {}, {}, {}>({
    url: '/api/ClientUsersApp/reset-password',
    method: 'post',
    ...variables,
    signal,
  });

export const useResetPassword = (
  options?: Omit<reactQuery.UseMutationOptions<string, ResetPasswordError, ResetPasswordVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<string, ResetPasswordError, ResetPasswordVariables>({
    mutationFn: (variables: ResetPasswordVariables) => fetchResetPassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ChangePasswordError = Fetcher.ErrorWrapper<undefined>;

export type ChangePasswordVariables = {
  body?: Schemas.ChangePasswordRequest;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchChangePassword = (variables: ChangePasswordVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<string, ChangePasswordError, Schemas.ChangePasswordRequest, {}, {}, {}>({
    url: '/api/ClientUsersApp/change-password',
    method: 'post',
    ...variables,
    signal,
  });

export const useChangePassword = (
  options?: Omit<reactQuery.UseMutationOptions<string, ChangePasswordError, ChangePasswordVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<string, ChangePasswordError, ChangePasswordVariables>({
    mutationFn: (variables: ChangePasswordVariables) => fetchChangePassword({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ApplyPasswordResetError = Fetcher.ErrorWrapper<undefined>;

export type ApplyPasswordResetVariables = {
  body?: Schemas.ApplyPasswordResetRequest;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchApplyPasswordReset = (variables: ApplyPasswordResetVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<string, ApplyPasswordResetError, Schemas.ApplyPasswordResetRequest, {}, {}, {}>({
    url: '/api/ClientUsersApp/apply-password-reset',
    method: 'post',
    ...variables,
    signal,
  });

export const useApplyPasswordReset = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, ApplyPasswordResetError, ApplyPasswordResetVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<string, ApplyPasswordResetError, ApplyPasswordResetVariables>({
    mutationFn: (variables: ApplyPasswordResetVariables) =>
      fetchApplyPasswordReset({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ValidatePasswordResetError = Fetcher.ErrorWrapper<undefined>;

export type ValidatePasswordResetVariables = {
  body?: Schemas.ValidatePasswordResetRequest;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchValidatePasswordReset = (variables: ValidatePasswordResetVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<string, ValidatePasswordResetError, Schemas.ValidatePasswordResetRequest, {}, {}, {}>({
    url: '/api/ClientUsersApp/validate-password-reset',
    method: 'post',
    ...variables,
    signal,
  });

export const useValidatePasswordReset = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, ValidatePasswordResetError, ValidatePasswordResetVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<string, ValidatePasswordResetError, ValidatePasswordResetVariables>({
    mutationFn: (variables: ValidatePasswordResetVariables) =>
      fetchValidatePasswordReset({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetUserSettingsError = Fetcher.ErrorWrapper<undefined>;

export type GetUserSettingsResponse = Schemas.UserSettingDto[];

export type GetUserSettingsVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetUserSettings = (variables: GetUserSettingsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<GetUserSettingsResponse, GetUserSettingsError, undefined, {}, {}, {}>({
    url: '/api/ClientUsersApp/user-settings',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetUserSettings = <TData = GetUserSettingsResponse>(
  variables: GetUserSettingsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetUserSettingsResponse, GetUserSettingsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<GetUserSettingsResponse, GetUserSettingsError, TData>({
    queryKey: queryKeyFn({
      path: '/api/ClientUsersApp/user-settings',
      operationId: 'getUserSettings',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetUserSettings({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type SaveUserSettingsError = Fetcher.ErrorWrapper<undefined>;

export type SaveUserSettingsRequestBody = Schemas.UserSettingDto[];

export type SaveUserSettingsVariables = {
  body?: SaveUserSettingsRequestBody;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchSaveUserSettings = (variables: SaveUserSettingsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, SaveUserSettingsError, SaveUserSettingsRequestBody, {}, {}, {}>({
    url: '/api/ClientUsersApp/user-settings',
    method: 'put',
    ...variables,
    signal,
  });

export const useSaveUserSettings = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SaveUserSettingsError, SaveUserSettingsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, SaveUserSettingsError, SaveUserSettingsVariables>({
    mutationFn: (variables: SaveUserSettingsVariables) => fetchSaveUserSettings({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeleteUserSettingsPathParams = {
  settingsKey: string;
};

export type DeleteUserSettingsError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserSettingsVariables = {
  pathParams: DeleteUserSettingsPathParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchDeleteUserSettings = (variables: DeleteUserSettingsVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, DeleteUserSettingsError, undefined, {}, {}, DeleteUserSettingsPathParams>({
    url: '/api/ClientUsersApp/user-settings/{settingsKey}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeleteUserSettings = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeleteUserSettingsError, DeleteUserSettingsVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, DeleteUserSettingsError, DeleteUserSettingsVariables>({
    mutationFn: (variables: DeleteUserSettingsVariables) =>
      fetchDeleteUserSettings({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetFeaturesAppError = Fetcher.ErrorWrapper<undefined>;

export type GetFeaturesAppResponse = Schemas.FeatureDto[];

export type GetFeaturesAppVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetFeaturesApp = (variables: GetFeaturesAppVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<GetFeaturesAppResponse, GetFeaturesAppError, undefined, {}, {}, {}>({
    url: '/api/features',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetFeaturesApp = <TData = GetFeaturesAppResponse>(
  variables: GetFeaturesAppVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetFeaturesAppResponse, GetFeaturesAppError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<GetFeaturesAppResponse, GetFeaturesAppError, TData>({
    queryKey: queryKeyFn({
      path: '/api/features',
      operationId: 'getFeaturesApp',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetFeaturesApp({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeleteUserInvitationCreationPathParams = {
  key: string;
};

export type DeleteUserInvitationCreationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserInvitationCreationVariables = {
  pathParams: DeleteUserInvitationCreationPathParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchDeleteUserInvitationCreation = (
  variables: DeleteUserInvitationCreationVariables,
  signal?: AbortSignal,
) =>
  clientServiceApiFetch<
    Schemas.UserInvitationCreationDto,
    DeleteUserInvitationCreationError,
    undefined,
    {},
    {},
    DeleteUserInvitationCreationPathParams
  >({ url: '/api/Invitation/{key}', method: 'delete', ...variables, signal });

export const useDeleteUserInvitationCreation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UserInvitationCreationDto,
      DeleteUserInvitationCreationError,
      DeleteUserInvitationCreationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<
    Schemas.UserInvitationCreationDto,
    DeleteUserInvitationCreationError,
    DeleteUserInvitationCreationVariables
  >({
    mutationFn: (variables: DeleteUserInvitationCreationVariables) =>
      fetchDeleteUserInvitationCreation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ReSendInvitationError = Fetcher.ErrorWrapper<undefined>;

export type ReSendInvitationVariables = {
  body?: Schemas.UserInvitationCreationDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchReSendInvitation = (variables: ReSendInvitationVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, ReSendInvitationError, Schemas.UserInvitationCreationDto, {}, {}, {}>({
    url: '/api/Invitation/ResendInvitation',
    method: 'post',
    ...variables,
    signal,
  });

export const useReSendInvitation = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ReSendInvitationError, ReSendInvitationVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, ReSendInvitationError, ReSendInvitationVariables>({
    mutationFn: (variables: ReSendInvitationVariables) => fetchReSendInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type SendInvitationError = Fetcher.ErrorWrapper<undefined>;

export type SendInvitationVariables = {
  body?: Schemas.UserInvitationCreationDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchSendInvitation = (variables: SendInvitationVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, SendInvitationError, Schemas.UserInvitationCreationDto, {}, {}, {}>({
    url: '/api/Invitation/SendInvitation',
    method: 'post',
    ...variables,
    signal,
  });

export const useSendInvitation = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, SendInvitationError, SendInvitationVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, SendInvitationError, SendInvitationVariables>({
    mutationFn: (variables: SendInvitationVariables) => fetchSendInvitation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ValidateInvitationTokenQueryParams = {
  invitationCode?: string;
};

export type ValidateInvitationTokenError = Fetcher.ErrorWrapper<undefined>;

export type ValidateInvitationTokenVariables = {
  queryParams?: ValidateInvitationTokenQueryParams;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchValidateInvitationToken = (variables: ValidateInvitationTokenVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, ValidateInvitationTokenError, undefined, {}, ValidateInvitationTokenQueryParams, {}>(
    {
      url: '/api/Invitation/ValidateInvitationToken',
      method: 'get',
      ...variables,
      signal,
    },
  );

export const useValidateInvitationToken = <TData = undefined>(
  variables: ValidateInvitationTokenVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ValidateInvitationTokenError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<undefined, ValidateInvitationTokenError, TData>({
    queryKey: queryKeyFn({
      path: '/api/Invitation/ValidateInvitationToken',
      operationId: 'validateInvitationToken',
      variables,
    }),
    queryFn: ({ signal }) => fetchValidateInvitationToken({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type CreateOrgRequestError = Fetcher.ErrorWrapper<undefined>;

export type CreateOrgRequestVariables = {
  body?: Schemas.OrganizationRequestAppDto;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchCreateOrgRequest = (variables: CreateOrgRequestVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<undefined, CreateOrgRequestError, Schemas.OrganizationRequestAppDto, {}, {}, {}>({
    url: '/api/OrganizationsApp/CreateOrgRequest',
    method: 'post',
    ...variables,
    signal,
  });

export const useCreateOrgRequest = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, CreateOrgRequestError, CreateOrgRequestVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<undefined, CreateOrgRequestError, CreateOrgRequestVariables>({
    mutationFn: (variables: CreateOrgRequestVariables) => fetchCreateOrgRequest({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetOrganizationAppError = Fetcher.ErrorWrapper<undefined>;

export type GetOrganizationAppVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetOrganizationApp = (variables: GetOrganizationAppVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<Schemas.OrganizationAppResponse, GetOrganizationAppError, undefined, {}, {}, {}>({
    url: '/api/OrganizationsApp',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganizationApp = <TData = Schemas.OrganizationAppResponse>(
  variables: GetOrganizationAppVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrganizationAppResponse, GetOrganizationAppError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<Schemas.OrganizationAppResponse, GetOrganizationAppError, TData>({
    queryKey: queryKeyFn({
      path: '/api/OrganizationsApp',
      operationId: 'getOrganizationApp',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetOrganizationApp({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutOrganizationAppError = Fetcher.ErrorWrapper<undefined>;

export type PutOrganizationAppVariables = {
  body: Schemas.OrganizationAppRequest;
} & ClientServiceApiContext['fetcherOptions'];

export const fetchPutOrganizationApp = (variables: PutOrganizationAppVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<
    Schemas.OrganizationAppResponse,
    PutOrganizationAppError,
    Schemas.OrganizationAppRequest,
    {},
    {},
    {}
  >({ url: '/api/OrganizationsApp', method: 'put', ...variables, signal });

export const usePutOrganizationApp = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationAppResponse,
      PutOrganizationAppError,
      PutOrganizationAppVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = useClientServiceApiContext();
  return reactQuery.useMutation<Schemas.OrganizationAppResponse, PutOrganizationAppError, PutOrganizationAppVariables>({
    mutationFn: (variables: PutOrganizationAppVariables) =>
      fetchPutOrganizationApp({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetRolesAppError = Fetcher.ErrorWrapper<undefined>;

export type GetRolesAppResponse = Schemas.RoleDto[];

export type GetRolesAppVariables = ClientServiceApiContext['fetcherOptions'];

export const fetchGetRolesApp = (variables: GetRolesAppVariables, signal?: AbortSignal) =>
  clientServiceApiFetch<GetRolesAppResponse, GetRolesAppError, undefined, {}, {}, {}>({
    url: '/api/RolesApp',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetRolesApp = <TData = GetRolesAppResponse>(
  variables: GetRolesAppVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetRolesAppResponse, GetRolesAppError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useClientServiceApiContext(options);
  return reactQuery.useQuery<GetRolesAppResponse, GetRolesAppError, TData>({
    queryKey: queryKeyFn({
      path: '/api/RolesApp',
      operationId: 'getRolesApp',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetRolesApp({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/api/ClientUsersApp';
      operationId: 'getClientUser';
      variables: GetClientUserVariables;
    }
  | {
      path: '/api/ClientUsersApp/get-menu-items';
      operationId: 'getMenuItems';
      variables: GetMenuItemsVariables;
    }
  | {
      path: '/api/ClientUsersApp/profile';
      operationId: 'getProfile';
      variables: GetProfileVariables;
    }
  | {
      path: '/api/ClientUsersApp/user-settings';
      operationId: 'getUserSettings';
      variables: GetUserSettingsVariables;
    }
  | {
      path: '/api/features';
      operationId: 'getFeaturesApp';
      variables: GetFeaturesAppVariables;
    }
  | {
      path: '/api/Invitation/ValidateInvitationToken';
      operationId: 'validateInvitationToken';
      variables: ValidateInvitationTokenVariables;
    }
  | {
      path: '/api/OrganizationsApp';
      operationId: 'getOrganizationApp';
      variables: GetOrganizationAppVariables;
    }
  | {
      path: '/api/RolesApp';
      operationId: 'getRolesApp';
      variables: GetRolesAppVariables;
    };
