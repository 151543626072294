import clsx from 'clsx';
import { cva } from 'class-variance-authority';

export type BadgeColor = 'main' | 'warning' | 'info' | 'disabled';
export type BadgeSize = 'sm' | 'md';

interface IProps {
  title: string;
  className?: string;
  classNameText?: string;
  color?: BadgeColor;
  size?: BadgeSize;
}

function Badge({ title, className = '', classNameText = '', color, size }: IProps) {
  return (
    <div
      className={styleVariation({
        size,
        color,
        className,
      })}
    >
      <span className={clsx('truncate text-center', classNameText)}>{title}</span>
    </div>
  );
}

const styleVariation = cva('inline-flex items-center justify-center py-0.5 opacity-80 line-clamp-1 font-medium', {
  variants: {
    size: {
      sm: 'text-2xs h-4 w-6 rounded-sm border-0 px-0',
      md: 'text-sm w-20 min-w-12  max-w-24 max-h-6 px-2.5 self-stretch rounded-[5px]',
    },
    color: {
      main: 'bg-bck-secondary text-txt-secondary',
      warning: 'bg-bck-warning text-warning-alt-light',
      info: 'bg-bck-info text-info-main',
      disabled: 'bg-action-disabled-bg text-txt-disabled',
    },
  },
  defaultVariants: {
    size: 'md',
    color: 'main',
  },
});

export default Badge;
