import clsx from 'clsx';
import type { ReactNode } from 'react';
import { cva } from 'class-variance-authority';
import type { Placement } from '@floating-ui/react';
import Tooltip from '@/components/common/tooltip/Tooltip';

export type IconButtonVariant = 'round' | 'square';
export type IconButtonSize = 'xs' | 'sm' | 'md' | 'lg';

interface IProps {
  WrappedIcon?: any;
  onClick?: (e: any) => void;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
  disabledTooltip?: boolean;
  tooltip?: string;
  children?: ReactNode;
  tooltipPosition?: Placement;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
  dataTestId?: string;
  toolTipOffset?: number;
  isClear?: boolean;
  tabIndex?: number;
  isFocused?: boolean;
  isPressed?: boolean;
}

function IconButton({
  WrappedIcon,
  onClick,
  className = '',
  iconClassName = '',
  disabled,
  disabledTooltip,
  tooltip,
  children,
  tooltipPosition,
  dataTestId: dataTestId,
  size = 'md',
  variant = 'round',
  toolTipOffset = 6,
  isClear = true,
  tabIndex,
  isFocused,
  isPressed,
}: IProps) {
  return (
    <Tooltip message={tooltip} disabled={disabledTooltip} position={tooltipPosition} toolTipOffset={toolTipOffset}>
      <button
        type="button"
        className={style({
          variant,
          size,
          isClear,
          className,
          isPressed,
        })}
        onClick={onClick}
        disabled={disabled}
        aria-label={tooltip}
        data-testid={dataTestId}
        tabIndex={tabIndex}
      >
        {WrappedIcon && (
          <WrappedIcon
            className={iconStyleVariations({
              disabled,
              className: iconClassName,
              isFocused,
            })}
            aria-hidden="true"
          />
        )}
        {!!children && (
          <span className={clsx('h-full w-full', iconClassName)} aria-hidden="true">
            {children}
          </span>
        )}
      </button>
    </Tooltip>
  );
}

const iconStyleVariations = cva('h-full w-full flex-shrink-0', {
  variants: {
    disabled: {
      true: 'pointer-events-none text-action-disabled',
    },
    isFocused: {
      true: 'text-primary-main',
    },
  },
});

const style = cva(
  'flex-shrink-0 p-0 m-1 text-txt-primary focus:outline-none shadow-none disabled:cursor-not-allowed disabled:text-action-disabled hover:opacity-75',
  {
    variants: {
      variant: {
        round: 'rounded-full',
        square: '',
      },
      size: {
        xs: 'h-5 w-5 p-0.5',
        sm: 'h-5 w-5',
        md: 'h-6 w-6',
        lg: 'h-8 w-8',
      },
      isClear: {
        false: 'bg-paper-card',
      },
      isPressed: {
        true: 'from-primary-main to-primary-alt-med bg-gradient-to-r disabled:from-transparent disabled:bg-action-disabled-bg',
      },
    },
    defaultVariants: {
      variant: 'round',
      size: 'md',
    },
  },
);

export default IconButton;
