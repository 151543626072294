import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { MdClose } from 'react-icons/md';
import type { INotification } from '@/interfaces/CommonInterfaces';
import NotificationIcon from './NotificationIcon';

interface IProps {
  notification: INotification;
  onMarkRead: (id: string) => void;
}

function ToastNotification({ notification, onMarkRead }: IProps) {
  const { type: variant, title, message, id, contents } = notification;
  const [show, setShow] = useState(true);
  if (!show) return null;

  const TIMEOUT_TOAST = 10_000;
  const hideTimer = setTimeout(() => setShow(false), TIMEOUT_TOAST);

  const handleClose = () => {
    onMarkRead(id);
    setShow(false);
    clearInterval(hideTimer);
  };

  const renderIcon = () => {
    return (
      <div className="flex-shrink-0">
        <NotificationIcon variant={variant} />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={styles.messageContainer}>
        {message ? <div className={styles.message}>{message}</div> : null}
        {contents ? <div className={styles.message}>{contents}</div> : null}
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <div className={styles.closeContainer}>
        <button type="button" className={styles.closeButton} onClick={handleClose}>
          <span className="sr-only">Close</span>
          <MdClose className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    );
  };

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={styles.container}>
        <div className={styles.content}>
          {renderIcon()}
          {renderContent()}
          {renderCloseButton()}
        </div>
      </div>
    </Transition>
  );
}

const styles = {
  container:
    'pointer-events-auto w-full max-w-sm overflow-hidden rounded-md bg-action-selected shadow-lg ring-1 ring-black ring-opacity-5',
  content: 'flex items-center pl-3 pr-4 py-2',
  messageContainer: 'ml-3 w-0 flex-1 pt-0.5 flex flex-col gap-1',
  title: 'text-md font-medium text-txt-primary',
  message: 'text-sm text-txt-primary',
  closeContainer: 'ml-4 flex flex-shrink-0',
  closeButton: 'inline-flex text-action-active hover:text-txt-secondary',
};

export default ToastNotification;
