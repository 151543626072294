import React, { Suspense } from 'react';
import i18n from '@/utils/i18n';
import {
  MdBusiness,
  MdCurrencyExchange,
  MdOutlineEvent,
  MdOutlineReceiptLong,
  MdSettingsApplications,
  MdSubscriptions,
} from 'react-icons/md';
import { DashboardIcon, InventoryIcon, MembersIcon, POSIcon, ReceiptLongIcon } from '../components/icons';
import type { IconBaseProps } from 'react-icons';
import { ConfigurationContextProvider } from '@/context/configuration/context';
import TemplatesListContextProvider from '../routes/templates/templatesListTable/templates-list-context';
import TemplatePageContextProvider from '../routes/templates/templatePage/template-page-context';
import { LoadingOverlay } from '@/components/common/loading';
import {
  CompanyInfo,
  Events,
  Inventory,
  Marketplace,
  PaymentsAndBilling,
  POSPage,
  Pricing,
  Subscription,
  TemplatePage,
  TemplatesList,
  Users,
  Billing,
  MarketComparables,
} from '@/routes';
import { ErrorFallback } from '@/components/common';
import { ErrorBoundary } from 'react-error-boundary';
import type { PredefinedMenuItem, SideBarMenuItem } from '@/interfaces/CommonInterfaces';
import Dashboard from '@/routes/Dashboard';
const lazyComponent = (component: React.ReactNode) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingOverlay size="lg" />}>{component}</Suspense>
    </ErrorBoundary>
  );
};

const companyInfoSubRoutes: { [key: string]: PredefinedMenuItem } = {
  pos: {
    key: 'menu_item.POS_Credentials',
    name: 'POS',
    path: '/settings/pos',
    icon: (props: IconBaseProps) => <POSIcon className={props?.className} width={18} height={18} />,
    component: lazyComponent(<POSPage />),
  },
  billing: {
    key: 'menu_item.Billing',
    name: 'Billing',
    path: '/settings/billing',
    icon: (props: IconBaseProps) => <ReceiptLongIcon className={props?.className} width={18} height={18} />,
    component: lazyComponent(<Billing />),
  },
  marketplace: {
    key: 'menu_item.Marketplace_Credentials',
    name: 'Marketplace',
    path: '/settings/marketplace',
    component: lazyComponent(<Marketplace />),
  },
  users: {
    key: 'menu_item.Users',
    name: i18n.t('users.navTitle'),
    path: '/settings/users',
    icon: MembersIcon,
    component: lazyComponent(<Users />),
  },
};

export const predefinedRoutes: { [key: string]: PredefinedMenuItem } = {
  ...companyInfoSubRoutes,
  dashboard: {
    key: 'menu_item.Dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    icon: (props: IconBaseProps) => <DashboardIcon className={props?.className} />,
    component: lazyComponent(<Dashboard />),
  },
  events: {
    key: 'menu_item.Events',
    name: 'Events',
    path: '/events',
    icon: MdOutlineEvent,
    component: lazyComponent(<Events />),
  },
  inventory: {
    key: 'menu_item.Inventory',
    name: 'Inventory',
    path: '/inventory',
    icon: (props: IconBaseProps) => <InventoryIcon className={props?.className} />,
    component: lazyComponent(<Inventory />),
  },
  templates: {
    key: 'menu_item.Pricing_Templates',
    name: 'Templates',
    path: '/pricing-templates',
    icon: MdCurrencyExchange,
    component: <TemplatesListContextProvider>{lazyComponent(<TemplatesList />)}</TemplatesListContextProvider>,
  },
  templatePage: {
    key: 'menu_item.Pricing_Templates',
    name: 'Apply pricing template',
    path: '/pricing-templates/:templateId',
    icon: MdCurrencyExchange,
    component: (
      <TemplatesListContextProvider>
        <TemplatePageContextProvider>{lazyComponent(<TemplatePage />)}</TemplatePageContextProvider>
      </TemplatesListContextProvider>
    ),
  },
  companyInfo: {
    key: 'menu_item.Company_Info',
    name: i18n.t('organization.navTitle'),
    path: '/settings',
    icon: MdBusiness,
    component: <ConfigurationContextProvider>{lazyComponent(<CompanyInfo />)}</ConfigurationContextProvider>,
    subItems: [
      companyInfoSubRoutes.pos,
      companyInfoSubRoutes.billing,
      companyInfoSubRoutes.marketplace,
      companyInfoSubRoutes.users,
    ],
  },
  paymentsAndBilling: {
    key: 'menu_item.Payments_and_Billing',
    name: 'Payments & Billing',
    path: '/payments',
    icon: MdOutlineReceiptLong,
    component: lazyComponent(<PaymentsAndBilling />),
  },
  subscription: {
    key: 'menu_item.Subscription',
    name: 'Subscription',
    path: '/subscription',
    icon: MdSubscriptions,
    component: lazyComponent(<Subscription />),
  },
  pricing: {
    key: 'menu_item.Pricing_Defaults',
    name: 'Org Settings',
    icon: MdSettingsApplications,
    path: '/OrganizationDefaults',
    component: lazyComponent(<Pricing />),
  },
  MarketComparables: {
    key: 'menu_item.Pricing_Defaults',
    name: 'Market Comparables',
    path: '/OrganizationDefaults/marketComparables',
    component: lazyComponent(<MarketComparables />),
  },
};

export const SidebarMenuItems: {
  home: { items: SideBarMenuItem[] };
  pricer: { items: SideBarMenuItem[] };
  systemSettings: { items: SideBarMenuItem[] };
} = {
  home: {
    items: [predefinedRoutes.dashboard],
  },
  pricer: {
    items: [predefinedRoutes.events, predefinedRoutes.inventory, predefinedRoutes.templates],
  },
  systemSettings: {
    items: [
      predefinedRoutes.companyInfo,
      predefinedRoutes.paymentsAndBilling,
      predefinedRoutes.users,
      predefinedRoutes.subscription,
      predefinedRoutes.pricing,
    ],
  },
};
