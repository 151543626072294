import { CgSpinner } from 'react-icons/cg';
import loadingSpinner from '@/assets/images/loading-spinner.svg';
import clsx from 'clsx';

interface IProps {
  size?: 'sm' | 'lg';
  className?: string;
}

function Loading({ className = '', size = 'sm' }: IProps) {
  return size === 'sm' ? (
    <CgSpinner className={clsx(styles.small, className)} />
  ) : (
    <img className={clsx(styles.large, className)} src={loadingSpinner} alt="" />
  );
}

const styles = {
  small: 'h-5 w-5 animate-spin text-primary-main',
  large: 'h-14 w-14 animate-spin',
};

export default Loading;
