import type { INotification, IQueryError, IQueryErrorItem } from '@/interfaces/CommonInterfaces';
import { NotificationsTypes } from './constants';
import i18n from './i18n';
import type { ReactNode } from 'react';

export function getErrorMessage(
  error: string,
  fallback: { title: string | undefined; message: string | undefined } = { title: undefined, message: undefined },
) {
  const title = i18n.t(`requestError.${error}.title`, {
    defaults: [
      {
        message: fallback.title ?? i18n.t('requestError.default.title'),
      },
    ],
  });

  const message = i18n.t(`requestError.${error}.message`, {
    defaults: [
      {
        message: fallback.message ?? i18n.t('requestError.default.message'),
      },
    ],
  });

  return { title, message };
}

export function getConcatenatedErrorMessage(errors: IQueryErrorItem[]) {
  const processedErrors = errors.map((error) => {
    return getErrorMessage(error.errorCode, { title: error.propertyName, message: error.errorMessage });
  });

  const title = processedErrors.map((error) => error.title).join(',');
  const message = processedErrors.map((error) => error.message).join(',');
  return { title, message };
}

export const getNotificationForNameAndCount = ({
  names,
  namespace,
  scope,
  variantWithNameAndPlural,
  variantWithoutName,
}: {
  names: (string | null | undefined)[];
  namespace: string;
  scope: 'applying' | 'success' | 'error';
  variantWithNameAndPlural: string;
  variantWithoutName: string;
}) => {
  if (!names.length) {
    throw new Error('No pricing groups provided. For no name pass an empty array');
  }
  let message = '';
  if (names.length === 1) {
    message = names[0]
      ? i18n.t(`${namespace}.${scope}.${variantWithNameAndPlural}`, {
          name: names[0],
          count: names.length,
        })
      : i18n.t(`${namespace}.${scope}.${variantWithoutName}`);
  } else {
    message = i18n.t(`${namespace}.${scope}.${variantWithNameAndPlural}`, {
      count: names.length,
    });
  }
  return message;
};

export function getLoadingNotification(id: string, title: string, message: string, keepId: boolean = false) {
  const createdAt = new Date().toISOString();
  return {
    id: keepId ? id : `${id}-${Date.now()}`,
    message,
    title,
    type: NotificationsTypes.LOADING,
    createdAt,
    isRead: false,
  } as INotification;
}

export function getSuccessNotification(
  id: string | undefined,
  title: string,
  message: string,
  keepId: boolean = false,
) {
  const createdAt = new Date().toISOString();
  return {
    id: keepId ? id : `${id}-${Date.now()}`,
    message,
    title,
    type: NotificationsTypes.SUCCESS,
    createdAt,
    isRead: false,
  } as INotification;
}

export function getSuccessNotificationWithRichContents(
  id: string | undefined,
  title: string,
  contents: ReactNode,
  keepId: boolean = false,
) {
  const createdAt = new Date().toISOString();
  return {
    id: keepId ? id : `${id}-${Date.now()}`,
    contents,
    title,
    type: NotificationsTypes.SUCCESS,
    createdAt,
    isRead: false,
  } as INotification;
}

export function getErrorNotification(
  id: string | undefined,
  error: unknown,
  keepId: boolean = false,
  title?: string,
  message?: string,
  providedErrorIsFallback: boolean = false,
) {
  const processedError = Array.isArray(error)
    ? getConcatenatedErrorMessage(error as IQueryErrorItem[])
    : getErrorMessage(error ? (error as IQueryError).message : '');

  const createdAt = new Date().toISOString();
  return {
    id: keepId ? id : `${id}-${Date.now()}`,
    title: providedErrorIsFallback ? processedError.title ?? title : title ?? processedError.title,
    message: providedErrorIsFallback ? processedError.message ?? message : message ?? processedError.message,
    type: NotificationsTypes.ERROR,
    createdAt: createdAt,
    isRead: false,
  } as INotification;
}
