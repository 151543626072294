import type { IDataField } from '@/interfaces/CommonInterfaces';
import { RadioGroup as Group } from '@headlessui/react';
import clsx from 'clsx';
import i18n from '@/utils/i18n';
import { Tooltip } from '@/components/common';
import { cva } from 'class-variance-authority';
import type { Placement } from '@floating-ui/react';
import { memo } from 'react';

interface IEnabledDataField extends IDataField {
  disabled?: boolean;
}

export type ButtonGroupVariant = 'primary' | 'secondary';
export type ButtonGroupSize = 'sm' | 'md';

interface IProps {
  label?: string;
  data: IEnabledDataField[];
  selected?: IDataField;
  onSelect: (value: IDataField) => void;
  className?: string;
  disabled?: boolean;
  canDeselect?: boolean;
  error?: boolean;
  inLine?: boolean;
  variant?: 'primary' | 'secondary';
  size?: ButtonGroupSize;
  tooltipMessageBase?: string;
  toolTipPosition?: Placement;
}

const ButtonGroup = memo(function ButtonGroup({
  label,
  data,
  selected,
  onSelect,
  className = '',
  disabled,
  canDeselect = false,
  error,
  inLine = false,
  tooltipMessageBase,
  toolTipPosition,
  variant = 'primary',
  size,
}: IProps) {
  const handleSelect = (value: string) => {
    const selection = data.find((d) => d.key === value);
    if (selection) onSelect(selection);
  };

  const isPrimary = variant === 'primary';
  const isSecondary = variant === 'secondary';
  return (
    <div
      className={clsx(
        styles.container,
        {
          'flex-col': !inLine,
          'flex-row items-center': inLine,
        },
        className,
      )}
    >
      {label ? <div className={labelStyleVariation({ size, inLine })}>{label}</div> : null}
      <Group
        value={selected?.key || ''}
        onChange={handleSelect}
        className={clsx(styles.content, error && !disabled && styles.error)}
        disabled={disabled}
      >
        {data.map((item, index) => {
          const isDisabled = disabled || item.disabled;
          return (
            <Group.Option
              key={item.key}
              value={item.key}
              className={styles.optionContainer}
              disabled={item.disabled}
              onClick={(e) => {
                if (canDeselect) {
                  e.preventDefault();
                  handleSelect(item.key ?? '');
                }
              }}
            >
              {({ checked }) => (
                <div
                  className={styleVariation({
                    variant,
                    checked,
                    disabled: !!isDisabled,
                    isFirst: index === 0,
                    isLast: index === data.length - 1,
                  })}
                >
                  <Tooltip
                    message={tooltipMessageBase ? i18n.t(`${tooltipMessageBase}.${item.name}`) : item.name}
                    position={toolTipPosition}
                    toolTipOffset={8}
                  >
                    <Group.Label as="span" className={styles.buttonLabel}>
                      {item.icon && (
                        <item.icon
                          className={clsx('h-[1.125rem] w-[1.125rem]', {
                            'fill-txt-disabled': isDisabled || (isSecondary && !checked),
                            'fill-txt-primary': !isDisabled && (isPrimary || (isSecondary && checked)),
                          })}
                        />
                      )}
                      {!item.hideName && (
                        <span
                          className={clsx('whitespace-nowrap', {
                            'text-txt-disabled': isDisabled || (isSecondary && !checked),
                          })}
                        >
                          {item.name}
                        </span>
                      )}
                    </Group.Label>
                  </Tooltip>
                </div>
              )}
            </Group.Option>
          );
        })}
      </Group>
    </div>
  );
});

const styles = {
  container: 'flex select-none',
  content: 'flex rounded bg-bck-secondary',
  optionContainer: 'flex flex-1 items-center justify-center cursor-pointer group relative',
  buttonLabel: 'flex items-center gap-x-1 text-xs font-medium text-txt-primary group',
  error: 'border border-error-main rounded-md',
};

const styleVariation = cva(
  'flex flex-1 items-center justify-center px-2 h-[1.625rem] border-y border-r border-divider',
  {
    variants: {
      variant: {
        primary: 'shadow-none',
        secondary: 'border shadow-sm',
      },
      checked: {
        true: '',
      },
      isFirst: {
        true: 'border-l rounded-l',
      },
      isLast: {
        true: 'rounded-r',
      },
      disabled: {
        true: 'cursor-not-allowed from-transparent to-transparent',
      },
    },
    compoundVariants: [
      {
        variant: 'primary',
        checked: true,
        disabled: false,
        className: 'bg-gradient-to-r from-primary-main to-primary-alt-med border-primary-main/60',
      },
      {
        variant: 'secondary',
        checked: true,
        className: 'bg-paper-card',
      },
      {
        variant: 'secondary',
        checked: false,
        className: 'bg-bck-light-secondary',
      },
      {
        variant: 'secondary',
        disabled: true,
        className: 'bg-bck-light-secondary/70',
      },
    ],
    defaultVariants: {
      variant: 'primary',
    },
  },
);

const labelStyleVariation = cva('text-txt-secondary font-medium', {
  variants: {
    size: {
      sm: 'text-2xs',
      md: 'text-xs leading-3',
    },
    inLine: {
      true: 'mr-2',
      false: 'mb-1',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export default ButtonGroup;
