import type { ReactNode } from 'react';
import { useState } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import i18n from '@/utils/i18n';
import { Button, Divider } from '@/components/common';
import MainAlert from '@/components/common/alerts/MainAlert';
import Checkbox from '../Checkbox';

interface IProps {
  isOpen?: boolean;
  title: string;
  statement: string;
  description: string;
  proceedCTA: string;
  onCancel: () => void;
  onProceed: (dontAsk?: boolean) => void;
  children?: ReactNode;
  variant?: 'primary' | 'warning';
  dontAskAgain?: boolean;
}

function WarningDialog({
  isOpen,
  title,
  statement,
  description,
  proceedCTA,
  onCancel,
  onProceed,
  children,
  variant = 'primary',
  dontAskAgain,
}: IProps) {
  const [dontAsk, setDontAsk] = useState(false);

  const reset = () => {
    setDontAsk(false);
  };
  const onProceedFinish = (dontAsk?: boolean) => {
    reset();
    onProceed(dontAsk);
  };
  const onCancelFinish = () => {
    reset();
    onCancel();
  };

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <div className={styles.container}>
        <Dialog onClose={onCancelFinish}>
          <Dialog.Panel>
            <div className="relative z-20" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div className={styles.backdrop} />

              <div className={styles.modalContainer}>
                <div className={styles.modalPosition}>
                  <div className={styles.modalSize}>
                    <div className="mb-6 px-5 pt-2">
                      <div className={styles.modalBody}>
                        <div className={styles.modalHeader}>
                          <h3 className={styles.modalTitle}>{title}</h3>
                          <span className={styles.modalCloseIcon} onClick={onCancelFinish}>
                            <svg className="h-4 w-4" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                              <path strokeWidth="1" d="M1 1l6 6m0-6L1 7" />
                            </svg>
                          </span>
                        </div>
                        <div className="mt-5">
                          <MainAlert
                            statement={statement}
                            description={description}
                            color={variant === 'primary' ? 'info' : 'warning'}
                            variant={variant === 'primary' ? 'primary' : 'error'}
                          />
                        </div>
                      </div>
                    </div>

                    {!!children && <div className={'mb-4 px-6'}>{children}</div>}
                    <Divider />
                    {dontAskAgain && (
                      <div className={'-mb-1 mt-3 flex justify-end px-6'}>
                        <Checkbox
                          label={i18n.t('dialogs.dontAskAgain')}
                          id="dontAskAgain"
                          name="dontAskAgain"
                          checked={dontAsk}
                          onChange={() => {
                            setDontAsk(!dontAsk);
                          }}
                          size="sm"
                        />
                      </div>
                    )}
                    <div className={styles.modalFooter}>
                      <Button variant="secondary" onClick={onCancelFinish}>
                        {i18n.t('dialogs.pricingProgressWarning.cancelCTA').toUpperCase()}
                      </Button>

                      <Button onClick={() => onProceedFinish(dontAsk)}>{proceedCTA}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </div>
    </Transition>
  );
}

const styles = {
  container: 'fixed left-0 top-0 h-full w-full',
  backdrop: 'fixed inset-0 bg-overlay bg-opacity-70 transition-opacity',
  modalContainer: 'fixed inset-0 z-20 w-screen overflow-y-auto',
  modalPosition: 'flex min-h-full items-end justify-center text-center sm:items-center',
  modalSize: 'relative w-[35rem] bg-paper-card transform overflow-hidden rounded text-left shadow-xl transition-all',
  modalBody: 'mt-3 w-full text-center sm:text-left',
  modalHeader: 'flex items-center justify-between',
  modalTitle: 'flex text-xl font-semibold leading-6 text-txt-primary',
  modalCloseIcon: 'text-txt-disabled cursor-pointer',
  modalFooter: 'flex justify-end gap-2 px-5 my-4',
  checkBox: 'mt-5 flex flex-row-reverse gap-2',
  buttons: 'inline-flex w-full justify-center rounded-md px-5 py-2 text-sm text-white sm:w-auto',
};

export default WarningDialog;
