import type { IconType } from 'react-icons';
import { BsFileEarmarkPdf } from 'react-icons/bs';
import { FaMobileAlt, FaTicketAlt } from 'react-icons/fa';
import { addMinutes, addYears, format, parseISO } from 'date-fns';
import type { IDataField } from '@/interfaces/CommonInterfaces';
import type { InventoryStatus, LowerOutlierMethod, ShareOptionTypes } from '@/api/pricer/pricerApiSchemas';
import i18n from './i18n';
import { AppMode, telemetry } from '@/utils/envConfig';
import type { StateCreator } from 'zustand';

export const enum Role {
  OWNER = 'owner',
  ADMIN = 'Admin',
  VIEWER = 'viewer',
  PRICER = 'Pricer',
  EMPLOYEE = 'employee',
}

export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';
export const FULL_DATE_TIME_USER_FORMAT = 'eee • MMM dd yyyy • h:mm aaa';
export const FULL_DATE_USER_FORMAT = 'eee • MMM dd, yyyy';

export const ServerIsoToUserDateTimeFormat = (date: string, asUtc = false) => {
  if (!date) return null;
  const localDate = parseISO(date);
  if (!asUtc) {
    return format(localDate, FULL_DATE_TIME_USER_FORMAT);
  } else {
    const utcDate = addMinutes(localDate, localDate.getTimezoneOffset());
    return format(utcDate, FULL_DATE_TIME_USER_FORMAT) + ' UTC';
  }
};

export enum UserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
  DELETED = 'Deleted',
  REDEEMED = 'Redeemed',
}

export enum UserStatusFilter {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  REDEEMED = 'REDEEMED',
}

export enum AdvancedOptionsPricingMethod {
  STANDARD = 'Standard',
  VARIABLE = 'Variable',
}

export const enum NotificationsTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  LOADING = 'loading',
}

export const telemetryConfig = {
  appInsightsKey: telemetry.appInsightsKey,
};

export const pageSizes = [10, 20, 30, 40, 50];

export const enum NavLocationType {
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

//pricer
export const enum PriceAdjustmentType {
  INCREASE = 'increase',
  DECREASE = 'decrease',
  MATCH = 'match',
}

export const enum PriceAdjustmentMethodType {
  PERCENTAGE = '%',
  DOLLAR = '$',
}

export const enum CustomSharingMethod {
  PERCENTAGE = '%',
  QUANTITY = '#',
}

export const enum RequiredOptionType {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
}

export const enum EnableDisabledType {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export const enum ShareUnshareType {
  SHARE = 'share',
  UNSHARE = 'unshare',
}

export const enum AutoPriceSimulateType {
  AUTO_PRICE = 'AutoPrice',
  SIMULATE = 'SimulationOnly',
}

export const enum SectionZoneOptionType {
  ZONE = 'zone',
  SECTION = 'section',
}

export const enum DisplayHideOptionType {
  DISPLAY = 'display',
  HIDE = 'hide',
}

export const groupShareOptionsTypes: { [key: string]: ShareOptionTypes } = {
  ALL: 'All',
  EVEN: 'Even',
  ODD: 'Odd',
  CUSTOM: 'Custom',
};

export const groupShareOptions: { name: string; key: ShareOptionTypes }[] = [
  { name: i18n.t('pricing.shareOptions.all').toUpperCase(), key: groupShareOptionsTypes.ALL },
  { name: i18n.t('pricing.shareOptions.even').toUpperCase(), key: groupShareOptionsTypes.EVEN },
  { name: i18n.t('pricing.shareOptions.odd').toUpperCase(), key: groupShareOptionsTypes.ODD },
  { name: i18n.t('pricing.shareOptions.custom').toUpperCase(), key: groupShareOptionsTypes.CUSTOM },
];

export enum StockType {
  PDF = 'Pdf',
  MOBILE_TRANSFER = 'MobileTransfer',
  PHYSICAL = 'Physical',
}

export const InventoryStatusOption: { [key: string]: InventoryStatus } = {
  AVAILABLE: 'Available',
  SOLD: 'Sold',
  HELD: 'Held',
  DELETED: 'Deleted',
};

export enum InventoryStatusEnum {
  AVAILABLE = 'Available',
  SOLD = 'Sold',
  HELD = 'Held',
  DELETED = 'Deleted',
}

export enum PricingGroupRequestType {
  AUTO_PRICE = 'AutoPrice',
  SIMULATION_ONLY = 'SimulationOnly',
}

export enum ProductionStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  CANCELED = 'Cancelled',
  POSTPONED = 'Postponed',
}

export enum EventMappingStatus {
  MAPPED = 'Mapped',
  UNMAPPED = 'Unmapped',
  PENDING = 'Pending',
}

export enum ProductionDateTimeStatus {
  SCHEDULED = 'Scheduled',
  TBD = 'TBD',
  TBA = 'TBA',
}

export const StockTypeIcon = new Map<string, IconType>([
  [StockType.PDF.toLowerCase(), BsFileEarmarkPdf],
  [StockType.PHYSICAL.toLowerCase(), FaTicketAlt],
  [StockType.MOBILE_TRANSFER.toLowerCase(), FaMobileAlt],
]);

export const NO_PRICER_ASSIGNED = 'unkPricer';
export const INDIVIDUAL_PRICING_GROUP_FLAG = -1;
export const DEFAULT_MISSING_TICKET_ID = '-1';
export const DEFAULT_MISSING_PRODUCTION_ID = '-1';
export const CREATE_PRICING_GROUP_ID = -2;

export enum PosKeys {
  SKYBOX = 'VSSkybox',
  TICKET_NETWORK_DIRECT = 'TicketNetworkDirect',
  TRADEDESK = 'TradeDesk',
}

export enum TNDPosKeys {
  POS11 = 'POS11',
  POS_NEXT = 'POSNext',
}

export enum MarketplaceKeys {
  TEVO = 'TicketEvolution',
  VSSkybox = 'VSSkybox',
}

export const tndPosOptions = [
  { name: TNDPosKeys.POS11, key: TNDPosKeys.POS11 },
  { name: TNDPosKeys.POS_NEXT, key: TNDPosKeys.POS_NEXT },
];
export const availablePosSystems = [
  { name: 'Skybox - Vivid Seats', key: PosKeys.SKYBOX },
  { name: 'TicketNetwork', key: PosKeys.TICKET_NETWORK_DIRECT },
] as IDataField[];

export const availableMarketplaceSystems = [{ name: 'Ticket Evolution', key: MarketplaceKeys.TEVO }] as IDataField[];

export enum ConnectionTestStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
}

type IAppMode = 'members' | 'drive';
export const AppModeIsDrive = AppMode === 'drive';

export const ColumnSizes = {
  maxColSize: 700,
  '5XL': 400,
  '4XL': 300,
  XXXL: 200,
  XXL: 150,
  XL135: 135,
  XL: 120,
  L110: 110,
  L: 100,
  M: 80,
  S: 60,
  XS: 44,
  XXS: 36,
};

export const yesNoOptions = [
  { name: 'Yes', key: 'yes' },
  { name: 'No', key: 'no' },
];

export enum YesNoOption {
  YES = 'yes',
  NO = 'no',
}

export enum ThreeStateOption {
  ANY = 'any',
  ONLY = 'only',
  HIDE = 'hide',
}

export const ThreeState = [
  { name: 'Any', key: ThreeStateOption.ANY },
  { name: 'Only', key: ThreeStateOption.ONLY },
  { name: 'Hide', key: ThreeStateOption.HIDE },
];

export enum InventoryFilterNames {
  status = 'status',
  pricingGroup = 'pricingGroup',
  seatType = 'seatType',
  splitRule = 'splitRule',
  stockType = 'stockType',
  attributes = 'attributes',
}

export const NinetyDays = 90;

const today = new Date();
const oneYearFromNow = addYears(today, 1);
export const DefaultDateRangeForEvents = [today, oneYearFromNow];

export const FeatureKeys = {
  CreatePricingTemplates: 'access_item.Create_Pricing_Templates',
};

export const PricingCriteriaInputMax = 9999999999;
export const PricingCriteriaIncrementInputMax = 99999;

export const ErrorCodes = {
  invalidTicketGroups: '002-026',
};
export const LowerOutlierMethodAccessible: { [key in LowerOutlierMethod]: LowerOutlierMethod } = {
  Quantity: 'Quantity',
  Currency: 'Currency',
  Percentage: 'Percentage',
};

export enum DateType {
  StartDate,
  EndDate,
}

export enum Alignment {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export const enum StepperInputType {
  DECIMAL = 'decimal',
  INTEGER = 'integer',
}

export enum DndType {
  Container = 'container',
  Item = 'item',
}

export type ImmerStateCreator<StateType, SliceType> = StateCreator<
  StateType,
  [['zustand/immer', never], never],
  [],
  SliceType
>;

export type ActionsSlice<T> = {
  actions: T;
};
const emptyArray: any[] = [];
export const getEmptyArray = <T>() => emptyArray as T[];
