import { createContext, useContext } from 'react';
import type * as Schemas from '@/api/pricer/pricerApiSchemas';
import type { OnChangeFn, PaginationState } from '@tanstack/react-table';
import type { IAssignPricerRequest } from '@/interfaces/productions/IAssignPricerRequest';
import type { IProductionQuickFilters, IProductionsFilters } from '@/interfaces/CommonInterfaces';
import type { GetTicketAttributesResponse } from '@/api/pricer/pricerApiComponents';
import type { IBulkAssignPricerRequest } from '@/interfaces/productions/IBulkAssignPricerRequest';

export type columnSortType = { columnName: string; sortDirection: Schemas.SortDirection | undefined };

export interface IPricerApi {
  setPagination: OnChangeFn<PaginationState>;
  refetchProductions: () => void;
  fetchNextProductionsPage: () => void;
  fetchPreviousProductionsPage: () => void;
  assignPricer: (req: IAssignPricerRequest) => void;
  setSelectedDateRange: (range: Date[]) => void;
  getDefaultDateRange: () => Date[];
  setSearchTerm: (term: string) => void;
  setRecentSearches: (term: string) => void;
  setFilters: (filters: IProductionsFilters) => void;
  setProductionQuickFilters: (filters: IProductionQuickFilters) => void;
  setSortColumns: (columns: columnSortType) => void;
  resetFilters: () => void;
  toggleRowSelected: (productionId: string) => void;
  toggleSelectAll: (rows?: string[]) => void;
  setPendingPricerAssignments: (rows: string[]) => void;
  assignProductionBulk: (bulkAssignPricerRequest: IBulkAssignPricerRequest) => void;
  setIsOpenMappingRequest: (isOpened: boolean) => void;
}

export interface IPricerState {
  pagination: PaginationState;
  productions: { data: Schemas.ProductionResponseDto[]; totalRecords: number; totalFilteredRecords: number };
  isProductionsSuccess: boolean;
  isProductionsError: boolean;
  isProductionsFetching: boolean;
  isProductionsLoading: boolean;
  productionsError: any;
  selectedDateRange: Date[];
  searchTerm: string;
  recentSearches: string[];
  filters: IProductionsFilters;
  productionQuickFilters: IProductionQuickFilters;
  appliedFiltersCount: number;
  sortColumns: columnSortType;
  pendingPricerAssignments: string[];
  productionsHasNextPage?: boolean;
  productionsHasPreviousPage?: boolean;
  tgAttributes: GetTicketAttributesResponse | undefined;
  organizationPricingMode: Schemas.OrganizationPricingMode | undefined;
  selectedProductionIds: string[];
  isOpenMappingRequest: boolean;
}

export const PricerStateContext = createContext<IPricerState | undefined>(undefined);
export const PricerApiContext = createContext<IPricerApi | undefined>(undefined);

function usePricerState() {
  const context = useContext(PricerStateContext);
  if (context === undefined) {
    throw new Error('usePricerState must be used within a PricerStateContext');
  }
  return context;
}

function usePricerApi() {
  const context = useContext(PricerApiContext);
  if (context === undefined) {
    throw new Error('usePricerApi must be used within a PricerApiContext');
  }
  return context;
}

export { usePricerState, usePricerApi };
