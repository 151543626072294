import logo_pos11 from '@/assets/images/pos/logo-pos11.svg';
import logo_posnext from '@/assets/images/pos/logo-posnext.svg';
import logo_skybox from '@/assets/images/pos/logo-skybox.svg';
import logo_tradedesk from '@/assets/images/pos/logo-tradedesk.svg';
import logo_m_tevo from '@/assets/images/marketplace/logo-tevo.svg';
import logo_m_seatgeek from '@/assets/images/marketplace/logo-seatgeek.svg';
import logo_m_ticketmaster from '@/assets/images/marketplace/logo-ticketmaster.svg';
import logo_m_vividseats from '@/assets/images/marketplace/logo-vividseats.svg';
import logo_m_stubhub from '@/assets/images/marketplace/logo-stubhub.svg';
import { PosKeys, MarketplaceKeys, TNDPosKeys } from '@/utils/constants';
import type { IPos } from '@/interfaces/pos/IPos';

export const getPosLogo = (posId: string) => {
  switch (posId) {
    case TNDPosKeys.POS11:
      return logo_pos11;
    case TNDPosKeys.POS_NEXT:
      return logo_posnext;
    case PosKeys.SKYBOX:
      return logo_skybox;
    case PosKeys.TRADEDESK:
      return logo_tradedesk;
  }
};

export const getPosUrl = (posId: string) => {
  switch (posId) {
    case TNDPosKeys.POS11:
      return 'https://portallogin.ticketnetwork.com/identity/login';
    case TNDPosKeys.POS_NEXT:
      return 'https://posnext.ticketnetwork.com';
    case PosKeys.SKYBOX:
      return 'https://skybox.vividseats.com';
    case PosKeys.TRADEDESK:
      return 'https://tradedesk.ticketmaster.com';
  }
};

export const getPosLabel = (posId: string) => {
  switch (posId) {
    case TNDPosKeys.POS11:
      return 'POS11 POS';
    case TNDPosKeys.POS_NEXT:
      return 'POSNext POS';
    case PosKeys.SKYBOX:
      return 'Skybox POS';
    case PosKeys.TRADEDESK:
      return 'TradeDesk POS';
  }
};

export const getPosType = (item: IPos) => {
  if (item.sourceType === PosKeys.TICKET_NETWORK_DIRECT) {
    return item.isPos11 ? TNDPosKeys.POS11 : TNDPosKeys.POS_NEXT;
  }
  return item.sourceType;
};

export const getMarketplaceLogo = (sourceType: string) => {
  switch (sourceType.toLowerCase()) {
    case MarketplaceKeys.TEVO.toLowerCase():
      return logo_m_tevo;
    case MarketplaceKeys.VSSkybox.toLowerCase():
      return logo_m_vividseats;
    case 'SeatGeek': //ToDo: this is a stub, replace with real id
      return logo_m_seatgeek;
    case 'TicketMaster': //ToDo: this is a stub, replace with real id
      return logo_m_ticketmaster;
    case 'StubHub': //ToDo: this is a stub, replace with real id
      return logo_m_stubhub;
  }
};

export const getTndTypeKey = (posType: string, isPos11: boolean) => {
  if (posType === PosKeys.TICKET_NETWORK_DIRECT) {
    return isPos11 ? TNDPosKeys.POS11 : TNDPosKeys.POS_NEXT;
  }
  return;
};
