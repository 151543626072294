import { Listbox, Transition } from '@headlessui/react';
import ctl from '@netlify/classnames-template-literals';
import { Fragment, useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import type { IDataField } from '@/interfaces/CommonInterfaces';
import i18n from '@/utils/i18n';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { CheckIcon } from '@/components/icons';
import { cva } from 'class-variance-authority';
import type { DropDownSizesType, DropDownVariantsType, DropDownRadiusType } from '../variations';

const fallbackValue = { key: '', name: i18n.t('notFound') };

interface IProps {
  data: IDataField[];
  className?: string;
  selected?: IDataField;
  label?: string;
  onChange: (value: IDataField) => void;
  placeholder?: string;
  renderTriggerContent?: (selectedItem: IDataField) => JSX.Element;
  renderOption?: (item: IDataField) => JSX.Element;
  variant?: DropDownVariantsType;
  menuOptionVariant?: 'primary' | 'secondary';
  size?: DropDownSizesType;
  radius?: DropDownRadiusType;
  dropIconClassName?: string;
  disabled?: boolean;
  isUnderlineVisible?: boolean;
  limitMinWidth?: boolean;
  selectedCheckIcon?: boolean;
}

function SelectionDropDown({
  data,
  className = '',
  selected: selectedItem,
  label,
  onChange,
  placeholder,
  renderTriggerContent,
  renderOption,
  variant = 'filled',
  size = 'xl',
  radius = 'sm',
  dropIconClassName,
  disabled = false,
  isUnderlineVisible = true,
  limitMinWidth = true,
  selectedCheckIcon = true,
  menuOptionVariant = 'primary',
}: IProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement);
  const showUnderline = isUnderlineVisible && variant !== 'outline' && variant !== 'clean';
  const showLabel = size === 'xl' && label;

  const renderTrigger = () => {
    return (
      <Listbox.Button
        className={variants({
          variant,
          size,
          radius,
          limitMinWidth,
        })}
        aria-disabled={disabled}
      >
        {({ open }) => (
          <>
            {renderTriggerContent && selectedItem ? (
              renderTriggerContent(selectedItem)
            ) : (
              <span
                className={clsx(styles.buttonText, {
                  'px-1 pt-4': size === 'xl',
                  'text-txt-disabled': !selectedItem,
                })}
              >
                {selectedItem ? selectedItem.name : placeholder ?? fallbackValue.name}
              </span>
            )}

            <span className="drop-down-button">
              <MdArrowDropDown
                className={clsx('drop-down-icon', dropIconClassName)}
                aria-hidden="true"
                aria-disabled={disabled}
              />
            </span>
            {showUnderline && (
              <div className={clsx(styles.underline, open ? styles.underlineActive : '')} aria-hidden="true" />
            )}
            {showLabel && (
              <label className={clsx(styles.label, open ? styles.labelActive : '')} aria-disabled={disabled}>
                {label}
              </label>
            )}
          </>
        )}
      </Listbox.Button>
    );
  };

  const renderMenuOption = (item: IDataField, index: number) => {
    return (
      <Listbox.Option
        key={index}
        className={({ active }) => menuOptionStyles({ active, menuOptionVariant, selectedCheckIcon })}
        value={item}
      >
        {({ active, selected }) => (
          <>
            {renderOption ? (
              renderOption(item)
            ) : (
              <span className={'block truncate text-base font-normal'}>{item.name}</span>
            )}
            {selectedCheckIcon && selected ? (
              <span className={clsx(styles.checkIcon, active ? styles.checkIconActive : '')}>
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </>
        )}
      </Listbox.Option>
    );
  };

  const renderMenu = () => {
    return (
      <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
        <Listbox.Options
          className="drop-down-menu scrollbar"
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {data.map((item, index) => renderMenuOption(item, index))}
        </Listbox.Options>
      </Transition>
    );
  };

  return (
    <div className={clsx(styles.container, className)}>
      <Listbox value={selectedItem ?? fallbackValue} onChange={onChange}>
        <div className={styles.content} ref={setReferenceElement}>
          {renderTrigger()}
          {renderMenu()}
        </div>
      </Listbox>
    </div>
  );
}

const styles = {
  container: 'z-10 my-2 w-full',
  content: 'relative',
  underline: 'absolute inset-x-0 bottom-0 border-t border-input-border',
  underlineActive: 'border-t-2 !border-primary-alt-med',
  checkIcon: 'absolute inset-y-0 left-0 flex items-center pl-3 text-txt-primary-alt-light',
  checkIconActive: '!text-white',
  buttonText: 'block truncate whitespace-nowrap',
  label: ctl(`
  block
  font-medium
  text-txt-secondary
  absolute
  -top-0.5
  left-0
  h-full
  px-3
  py-5
  text-xs
  order-2
  transition-all
  duration-100
  ease-in-out
  origin-left
  transform
  translate-x-1
  -translate-y-3
  opacity-75
  pointer-events-none
  aria-disabled:text-txt-disabled`),
  labelActive: '!text-primary-main',
};

const variants = cva(
  'px-3 placeholder-transparent block w-full border-input-border focus:border-primary-main hover:opacity-75 text-txt-primary text-md text-left aria-disabled:bg-action-disabled-bg aria-disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        filled: 'bg-filled-input-bg rounded-t-md shadow-sm border-0',
        outline:
          'bg-transparent py-0.5 px-0.5 [:where(&)]:rounded-sm border focus:box-shadow-none selection:bg-action-selected aria-disabled:border-0',
        clean: 'bg-filled-input-bg py-0.5 px-0.5 focus:box-shadow-none selection:bg-action-selected',
      },
      limitMinWidth: {
        true: '',
      },
      size: {
        sm: 'h-5 text-xs',
        md: 'h-[1.625rem] px-1 text-xs',
        lg: 'h-8 px-3 text-md',
        xl: 'h-[3.25rem] px-3 text-base',
      },
      radius: {
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-lg',
      },
    },
    defaultVariants: {
      variant: 'filled',
      size: 'xl',
    },
    compoundVariants: [
      {
        limitMinWidth: true,
        size: 'sm',
        className: 'min-w-32',
      },
      {
        limitMinWidth: true,
        size: 'md',
        className: 'min-w-32',
      },
      {
        limitMinWidth: true,
        size: 'lg',
        className: 'min-w-32',
      },
      {
        limitMinWidth: true,
        size: 'xl',
        className: 'min-w-48',
      },
    ],
  },
);

const menuOptionStyles = cva('relative cursor-pointer select-none py-2 pr-4 text-md text-txt-secondary', {
  variants: {
    menuOptionVariant: {
      primary: 'text-txt-secondary',
      secondary: 'text-txt-secondary-alt',
    },
    active: {
      true: '',
    },
    selectedCheckIcon: {
      true: 'pl-10',
      false: 'pl-3',
    },
  },
  defaultVariants: {
    menuOptionVariant: 'primary',
  },
  compoundVariants: [
    {
      menuOptionVariant: 'primary',
      active: true,
      className: 'bg-primary-alt-light text-white',
    },
    {
      menuOptionVariant: 'secondary',
      active: true,
      className: 'bg-bck-secondary',
    },
  ],
});

export default SelectionDropDown;
