import clsx from 'clsx';
import CheckIcon from '@material-symbols/svg-200/outlined/check.svg';
import { cva } from 'class-variance-authority';
import Tooltip from '@/components/common/tooltip/Tooltip';
import React from 'react';
import CheckboxIndeterminateIcon from '@/components/icons/CheckboxIndeterminateIcon';
import { renderToStaticMarkup } from 'react-dom/server';

export type CheckBoxSize = 'sm' | 'md';

interface IProps {
  name: string;
  id: string;
  label: string | React.ReactNode;
  tooltip?: string;
  className?: string;
  checked: boolean | 'some';
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  LeadingIcon?: React.ReactNode;
  size?: CheckBoxSize;
  children?: React.ReactNode;
}

function Checkbox({
  name,
  id,
  label,
  tooltip,
  className = '',
  checked = false,
  onChange,
  disabled,
  inputRef,
  LeadingIcon,
  size,
  children,
}: IProps) {
  const tip = tooltip ? tooltip : typeof label === 'string' ? label : '';

  return (
    <div className={clsx('relative flex items-center', className)}>
      <div className="flex h-5 items-center">
        <input
          id={id}
          aria-describedby="comments-description"
          name={name}
          type="checkbox"
          className={inputStyleVariation({
            size,
            variant: checked === 'some' ? 'indeterminate' : 'default',
          })}
          checked={!!checked}
          onChange={onChange}
          ref={inputRef}
          disabled={disabled}
          style={{
            backgroundImage: `url("${
              checked === 'some'
                ? `data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(<CheckboxIndeterminateIcon />))}`
                : CheckIcon
            }")`,
          }}
        />
      </div>
      {label && (
        <Tooltip message={tip}>
          <div>
            <label htmlFor={id} className={labelStyleVariation({ size, disabled })}>
              {LeadingIcon}
              {label}
            </label>
            {children}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

const labelStyleVariation = cva('ml-3 flex items-center select-none', {
  variants: {
    size: {
      sm: 'text-xs font-normal',
      md: 'text-sm font-medium ',
    },
    disabled: {
      true: 'text-txt-disabled',
      false: 'text-txt-primary',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
const inputStyleVariation = cva(
  'cursor-pointer rounded-sm  focus:ring-offset-0 focus:ring-0 disabled:cursor-not-allowed disabled:text-action-disabled disabled:bg-action-disabled disabled:border-action-disabled',
  {
    variants: {
      size: {
        sm: 'h-3 w-3 border border-primary-main',
        md: 'h-4 w-4 border-2 border-txt-secondary',
      },
      variant: {
        default: 'bg-transparent text-primary-main',
        indeterminate: 'bg-transparent text-bck-body !border-primary-main',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  },
);

export default Checkbox;
