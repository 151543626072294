import type { IDataField, TField } from '@/interfaces/CommonInterfaces';
import type { OrganizationPricingDefaultDto } from '@/api/pricer/pricerApiSchemas';
import { enableDisableOption } from '@/context/pricing-mode/constants';
import { emptyDataField, handleDeSelect, INITIAL_STATE_MARKET_DEFAULT } from '../constants';
import InputValidations from '@/utils/InputValidations';

export enum FieldType {
  SPLITS = 'splits',
  SECTIONS = 'sections',
  ROWS = 'rows',
  ROWS_IN_FRONT = 'rowsIncludedAhead',
  ROWS_BEHIND = 'rowsIncludedBehind',
  DYNAMIC_TOGGLE = 'dynamicToggle',
}

export const enum REDUCER_ACTION_TYPE {
  SET_SPLITS,
  SET_SECTIONS,
  SET_ROWS,
  SET_DYNAMIC_TOGGLE,
  LOAD_STATE_FROM_ORG_DEFAULTS,
  RESET,
  SET_ERROR,
  CHANGE_INPUT,
  SET_INPUT_VALIDATIONS,
}

type InputAction = {
  type: REDUCER_ACTION_TYPE.CHANGE_INPUT;
  payload: { name: string; value: string };
};

type InputValidationAction = {
  type: REDUCER_ACTION_TYPE.SET_INPUT_VALIDATIONS;
  payload: {
    [key: string]: { value: string; valid: boolean };
  };
};

type SetDataFieldAction = {
  type: REDUCER_ACTION_TYPE.SET_SPLITS | REDUCER_ACTION_TYPE.SET_SECTIONS | REDUCER_ACTION_TYPE.SET_ROWS;
  payload: IDataField;
};

type SetError = {
  type: REDUCER_ACTION_TYPE.SET_ERROR;
  payload: boolean;
};

type SimpleAction = {
  type: REDUCER_ACTION_TYPE.RESET | REDUCER_ACTION_TYPE.SET_DYNAMIC_TOGGLE;
};

type StateLoadAction = {
  type: REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS;
  payload: OrganizationPricingDefaultDto | undefined;
};

export type MarketDefaultReducerAction =
  | SimpleAction
  | SetDataFieldAction
  | StateLoadAction
  | SetError
  | InputAction
  | InputValidationAction;

export const maximumRowCount = 100;

const getValidatedInput = (
  name: string,
  value: string,
  validateEmpty: boolean = true,
): { value: string; valid: boolean | undefined } => {
  if (name === FieldType.ROWS_IN_FRONT || name === FieldType.ROWS_BEHIND) {
    if (value === '') return { value: '', valid: validateEmpty ? false : undefined };
    const rowValue = parseInt(value);
    return {
      value: isNaN(rowValue) ? '' : rowValue.toString(),
      valid: InputValidations.isNumbersOnly(rowValue.toString()) && rowValue <= maximumRowCount,
    };
  }

  return { value, valid: undefined };
};

const handleInitialEnableState = (value: boolean | null | undefined) => {
  return value === undefined ? emptyDataField : value ? enableDisableOption[0] : enableDisableOption[1];
};

export const marketDefaultReducer = (
  state: typeof INITIAL_STATE_MARKET_DEFAULT,
  action: MarketDefaultReducerAction,
): typeof INITIAL_STATE_MARKET_DEFAULT => {
  switch (action.type) {
    case REDUCER_ACTION_TYPE.CHANGE_INPUT: {
      const { name, value } = action.payload;

      const currentValue = (state[name as keyof typeof INITIAL_STATE_MARKET_DEFAULT] as TField).value;
      const validatedInput = getValidatedInput(name, value);
      const isDirty = state.isDirty || currentValue !== value;
      return {
        ...state,
        [name]: { value: validatedInput.value, valid: validatedInput.valid },
        isDirty,
        isError: !validatedInput.valid,
      };
    }
    case REDUCER_ACTION_TYPE.SET_INPUT_VALIDATIONS:
      return { ...state, ...action.payload };
    case REDUCER_ACTION_TYPE.SET_DYNAMIC_TOGGLE:
      return { ...state, splitsDynamicToggle: !state.splitsDynamicToggle, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_SPLITS:
      return { ...state, splitsEnabled: handleDeSelect(action.payload, state.splitsEnabled), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_SECTIONS:
      return { ...state, sectionEnabled: handleDeSelect(action.payload, state.sectionEnabled), isDirty: true };
    case REDUCER_ACTION_TYPE.SET_ROWS:
      return { ...state, rowEnabled: handleDeSelect(action.payload, state.rowEnabled), isDirty: true };
    case REDUCER_ACTION_TYPE.LOAD_STATE_FROM_ORG_DEFAULTS:
      const orgDefaults = action.payload ?? {};
      if (orgDefaults) {
        const {
          isSplitsDynamic,
          isSplitsEnabled,
          isSectionEnabled,
          isRowEnabled,
          rowsIncludedAhead,
          rowsIncludedBehind,
        } = orgDefaults.marketCriteriaDefaults ?? {};

        return {
          ...INITIAL_STATE_MARKET_DEFAULT,
          splitsDynamicToggle: !!isSplitsDynamic,
          splitsEnabled: handleInitialEnableState(isSplitsEnabled),
          sectionEnabled: handleInitialEnableState(isSectionEnabled),
          rowEnabled: handleInitialEnableState(isRowEnabled),
          rowsIncludedAhead: !isNaN(Number(rowsIncludedAhead))
            ? getValidatedInput(FieldType.ROWS_IN_FRONT, (rowsIncludedAhead ?? '').toString())
            : INITIAL_STATE_MARKET_DEFAULT.rowsIncludedAhead,
          rowsIncludedBehind: !isNaN(Number(rowsIncludedBehind))
            ? getValidatedInput(FieldType.ROWS_BEHIND, (rowsIncludedBehind ?? '').toString())
            : INITIAL_STATE_MARKET_DEFAULT.rowsIncludedBehind,
        };
      } else {
        return INITIAL_STATE_MARKET_DEFAULT;
      }
    case REDUCER_ACTION_TYPE.RESET:
      return { ...INITIAL_STATE_MARKET_DEFAULT, isDirty: true };
    case REDUCER_ACTION_TYPE.SET_ERROR:
      return { ...state, isError: action.payload };
    default:
      return state;
  }
};
