import type { ActionsSlice, ImmerStateCreator } from '@/utils/constants';
import type { InventoryState } from '@/stores/inventory/inventory-store';
import { InventoryStatusKeys } from '@/api/pricer/pricerApiSchemaExtensions';
import { UniqueArrayPrimitives } from '@/utils/helpers';

export type selectAllOptionsType = 'TicketGroups' | 'PricingGroups';

type AllSelected = {
  [key in selectAllOptionsType]: boolean;
};

type SelectedWithIndeterminate = {
  [key in selectAllOptionsType]: boolean | 'some';
};

export type SelectionModeSlice = {
  isAllSelected: SelectedWithIndeterminate;
  isSelectionModeDisabled: AllSelected;
  selectedOption: selectAllOptionsType;
  addedTicketsForNewPricingGroup: number[];
  selectedPricingGroupIds: number[];
};

type SelectionModeActions = {
  setSelectedOption: (option: selectAllOptionsType) => void;
  resetTicketsForNewPricingGroup: () => void;
  addTicketForNewPricingGroup: (ticketId: number) => void;
  toggleSelectedPriceGroupId: (priceGroupId: number) => void;
  resetSelectionsForTicketsAndPricingGroups: () => void;
  toggleSelectAll: () => void;
  updateSelectionModeBasedOnActivePricingGroup: () => void;
  markIsAllSelectedBasedOnUnassignedTickets: (countOfUnassignedTickets: number) => void;
};

export type SelectionModeSliceState = SelectionModeSlice & ActionsSlice<SelectionModeActions>;

export const createSelectionModeSlice: ImmerStateCreator<InventoryState, SelectionModeSliceState> = (set, get) => ({
  isAllSelected: {
    TicketGroups: false,
    PricingGroups: false,
  },
  isSelectionModeDisabled: {
    TicketGroups: false,
    PricingGroups: false,
  },
  selectedOption: 'TicketGroups',
  addedTicketsForNewPricingGroup: [],
  selectedPricingGroupIds: [],

  actions: {
    setSelectedOption: (targetSelectedOption) =>
      set(
        (state) => {
          let TicketGroupsAllSelected = false;
          let PricingGroupsAllSelected = false;
          const selectionModeSlice = state.selectionModeSlice;
          const mainSlice = get().mainSlice;

          if (selectionModeSlice.selectedOption === targetSelectedOption) {
            if (selectionModeSlice.isAllSelected[targetSelectedOption]) {
              if (targetSelectedOption === 'TicketGroups') {
                selectionModeSlice.addedTicketsForNewPricingGroup = [];

                updateBasedOnAddedTickets(selectionModeSlice);
              } else {
                selectionModeSlice.selectedPricingGroupIds = [];
                updateStateBasedOnSelectedPricingGroups(state, mainSlice);
              }
            } else {
              if (targetSelectedOption === 'TicketGroups') {
                selectionModeSlice.addedTicketsForNewPricingGroup = getAllUnassignedTickets(state);
                updateBasedOnAddedTickets(selectionModeSlice);
                TicketGroupsAllSelected = true;
              } else {
                selectionModeSlice.selectedPricingGroupIds = getPricingGroups(mainSlice);
                updateStateBasedOnSelectedPricingGroups(state, mainSlice);
                PricingGroupsAllSelected = true;
              }
            }
          } else {
            if (selectionModeSlice.selectedOption === 'TicketGroups') {
              selectionModeSlice.addedTicketsForNewPricingGroup = [];
              selectionModeSlice.selectedPricingGroupIds = getPricingGroups(mainSlice);
              PricingGroupsAllSelected = true;
            } else {
              selectionModeSlice.addedTicketsForNewPricingGroup = getAllUnassignedTickets(state);
              selectionModeSlice.selectedPricingGroupIds = [];
              TicketGroupsAllSelected = true;
            }

            updateBasedOnAddedTickets(selectionModeSlice);
            updateStateBasedOnSelectedPricingGroups(state, mainSlice);
          }

          selectionModeSlice.selectedOption = targetSelectedOption;
          selectionModeSlice.isAllSelected = {
            TicketGroups: TicketGroupsAllSelected,
            PricingGroups: PricingGroupsAllSelected,
          };
        },
        false,
        {
          type: 'inv:selection/setSelectedOption',
        },
      ),
    markIsAllSelectedBasedOnUnassignedTickets: (countOfUnassignedTickets) =>
      set(
        ({ selectionModeSlice }) => {
          if (
            selectionModeSlice.addedTicketsForNewPricingGroup.length === countOfUnassignedTickets &&
            countOfUnassignedTickets !== 0
          ) {
            selectionModeSlice.isAllSelected.TicketGroups = true;
          } else if (selectionModeSlice.addedTicketsForNewPricingGroup.length > 0) {
            selectionModeSlice.isAllSelected.TicketGroups = 'some';
          } else {
            selectionModeSlice.isAllSelected.TicketGroups = false;
          }
        },
        false,
        {
          type: 'inv:selection/markIsAllSelectedBasedOnUnassignedTickets',
        },
      ),

    resetTicketsForNewPricingGroup: () =>
      set(
        ({ selectionModeSlice }) => {
          setTicketsForNewPricingGroup([], selectionModeSlice);
        },
        false,
        {
          type: 'inv:selection/resetTicketsForNewPricingGroup',
        },
      ),

    addTicketForNewPricingGroup: (ticketId) =>
      set(
        ({ selectionModeSlice }) => {
          let ticketsForNewPG = selectionModeSlice.addedTicketsForNewPricingGroup;
          if (ticketsForNewPG.includes(ticketId)) {
            ticketsForNewPG = ticketsForNewPG.filter((id) => id !== ticketId);
          } else {
            ticketsForNewPG = [...ticketsForNewPG, ticketId];
          }
          setTicketsForNewPricingGroup(ticketsForNewPG, selectionModeSlice);
        },
        false,
        {
          type: 'inv:selection/addTicketForNewPricingGroup',
        },
      ),

    toggleSelectedPriceGroupId: (priceGroupId) =>
      set(
        (state) => {
          const selectionModeSlice = state.selectionModeSlice;
          const selectedPricingGroupIds = selectionModeSlice.selectedPricingGroupIds;
          if (selectedPricingGroupIds.includes(priceGroupId)) {
            selectionModeSlice.selectedPricingGroupIds = selectedPricingGroupIds.filter((id) => id !== priceGroupId);
          } else {
            selectionModeSlice.selectedPricingGroupIds = [...selectedPricingGroupIds, priceGroupId];
          }
          updateStateBasedOnSelectedPricingGroups(state, get().mainSlice);
        },
        false,
        {
          type: 'inv:selection/toggleSelectedPriceGroupId',
        },
      ),

    resetSelectionsForTicketsAndPricingGroups: () =>
      set(
        ({ selectionModeSlice }) => {
          selectionModeSlice.addedTicketsForNewPricingGroup = [];
          selectionModeSlice.selectedPricingGroupIds = [];
          selectionModeSlice.isAllSelected = {
            TicketGroups: false,
            PricingGroups: false,
          };
          selectionModeSlice.selectedOption = 'TicketGroups';
          selectionModeSlice.isSelectionModeDisabled = {
            TicketGroups: false,
            PricingGroups: false,
          };
        },
        false,
        {
          type: 'inv:selection/resetSelectionsForTicketsAndPricingGroups',
        },
      ),

    toggleSelectAll: () =>
      set(
        (state) => {
          const selectionModeSlice = state.selectionModeSlice;
          const isAllSelected = selectionModeSlice.isAllSelected;
          const mainSlice = get().mainSlice;
          if (selectionModeSlice.isAllSelected[selectionModeSlice.selectedOption]) {
            if (selectionModeSlice.selectedOption === 'TicketGroups') {
              selectionModeSlice.addedTicketsForNewPricingGroup = [];
              updateBasedOnAddedTickets(selectionModeSlice);
              selectionModeSlice.isAllSelected[selectionModeSlice.selectedOption] =
                !isAllSelected[selectionModeSlice.selectedOption];
            } else {
              selectionModeSlice.selectedPricingGroupIds = [];
              updateStateBasedOnSelectedPricingGroups(state, mainSlice);
            }
          } else {
            if (selectionModeSlice.selectedOption === 'TicketGroups') {
              selectionModeSlice.addedTicketsForNewPricingGroup = getAllUnassignedTickets(state);
              updateBasedOnAddedTickets(selectionModeSlice);
              selectionModeSlice.isAllSelected[selectionModeSlice.selectedOption] =
                !isAllSelected[selectionModeSlice.selectedOption];
            } else {
              selectionModeSlice.selectedPricingGroupIds = getPricingGroups(get().mainSlice);
              updateStateBasedOnSelectedPricingGroups(state, mainSlice);
            }
          }
        },
        false,
        {
          type: 'inv:selection/toggleSelectAll',
        },
      ),

    updateSelectionModeBasedOnActivePricingGroup: () =>
      set(
        ({ selectionModeSlice }) => {
          const activePricingGroup = get().mainSlice.activePricingGroup;
          if (activePricingGroup) {
            selectionModeSlice.selectedPricingGroupIds = [];
            selectionModeSlice.isSelectionModeDisabled = { TicketGroups: true, PricingGroups: true };
          } else {
            selectionModeSlice.isSelectionModeDisabled = { TicketGroups: false, PricingGroups: false };
          }
        },
        false,
        {
          type: 'inv:selection/updateSelectionModeBasedOnActivePricingGroup',
        },
      ),
  },
});

function updateBasedOnAddedTickets(slice: SelectionModeSliceState) {
  if (slice.addedTicketsForNewPricingGroup.length === 0) {
    slice.isSelectionModeDisabled.PricingGroups = false;
  } else {
    slice.isSelectionModeDisabled.PricingGroups = true;
    slice.selectedOption = 'TicketGroups';
  }
}

function updateStateBasedOnSelectedPricingGroups(state: InventoryState, mainSlice: InventoryState['mainSlice']) {
  const pricingGroups = getPricingGroups(mainSlice);
  const activePricingGroup = state.mainSlice.activePricingGroup;
  if (
    state.selectionModeSlice.selectedPricingGroupIds.length === pricingGroups?.length &&
    pricingGroups?.length !== 0
  ) {
    state.selectionModeSlice.isAllSelected.PricingGroups = true;
  } else if (state.selectionModeSlice.selectedPricingGroupIds.length > 0) {
    state.selectionModeSlice.isAllSelected.PricingGroups = 'some';
  } else {
    state.selectionModeSlice.isAllSelected.PricingGroups = false;
  }

  if (activePricingGroup) return;

  if (state.selectionModeSlice.selectedPricingGroupIds.length === 0) {
    state.selectionModeSlice.isSelectionModeDisabled.TicketGroups = false;
  } else {
    state.selectionModeSlice.isSelectionModeDisabled.TicketGroups = true;
    state.selectionModeSlice.selectedOption = 'PricingGroups';
  }
}

function getPricingGroups(mainSlice: InventoryState['mainSlice']) {
  return Array.from(mainSlice.pricingGroupsMap.keys());
}

function getAllUnassignedTickets(state: InventoryState) {
  const processedInventory = state.mainSlice.processedInventory;
  const filteredTicketIds = state.mainSlice.filteredTicketIds;
  if (!processedInventory) {
    return [];
  }
  const unassignedTickets = processedInventory
    ?.filter(
      (item) =>
        !item.pricingGroupId &&
        item.inventoryStatus?.toLowerCase() === InventoryStatusKeys.Available.toLowerCase() &&
        filteredTicketIds.includes(item.ticketGroupId!),
    )
    .map((item) => item.ticketGroupId!);
  return UniqueArrayPrimitives([...state.selectionModeSlice.addedTicketsForNewPricingGroup, ...unassignedTickets]);
}

function setTicketsForNewPricingGroup(ticketsForNewPG: number[], slice: SelectionModeSliceState) {
  slice.addedTicketsForNewPricingGroup = ticketsForNewPG;
  updateBasedOnAddedTickets(slice);
}
