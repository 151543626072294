import format from 'date-fns/format';
import { forwardRef, useEffect, useState } from 'react';
import type { DateType } from '@/utils/constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdEventAvailable } from 'react-icons/md';
import React, { useRef } from 'react';
import i18n from '@/utils/i18n';
import Button from '../Button';
import { dateFormatFullYear, isValidDate, ParseInputDate } from '@/components/common/date/parser-utils';
import { isSameDay } from 'date-fns';
import IconButton from '../IconButton';
import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';
import type { Placement } from '@floating-ui/react';
import type { TextInputSizesType } from '@/components/common/TextInput';
import TextInput from '@/components/common/TextInput';

export type DateInputVariantsType = 'default' | 'inline';
const formatDate = (date: Date): string => format(date, dateFormatFullYear);

type ButtonProps = {
  isFocused: boolean;
  disabled?: boolean;
  size: TextInputSizesType;
  onClick?: (event: React.MouseEvent) => void;
};
const CalendarIconWrapper = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ onClick, isFocused, disabled, size }: ButtonProps, ref) => {
    const handleClick = (event: React.MouseEvent) => {
      onClick?.(event as unknown as any);
    };

    return (
      <IconButton
        WrappedIcon={MdEventAvailable}
        onClick={handleClick}
        isFocused={isFocused}
        disabled={disabled}
        tabIndex={-1}
        size={size}
        iconClassName="hover:text-primary-main"
      />
    );
  },
);

interface IProps extends VariantProps<typeof containerStyleVariation> {
  date: Date;
  label: string;
  onDateChange?: (date: Date, inputType: DateType) => void;
  onResetDate?: () => void;
  type: DateType;
  disabled?: boolean;
  toolTipMessage?: string;
  toolTipPosition?: Placement;
  popperPlacement?: Placement;
  minDate?: Date;
  maxDate?: Date;
}
const DateInput = ({
  date,
  label,
  onDateChange,
  type,
  onResetDate,
  variant = 'default',
  size = 'sm',
  toolTipMessage,
  toolTipPosition,
  disabled,
  popperPlacement = 'bottom',
  minDate,
  maxDate,
}: IProps) => {
  const [localDate, setLocalDate] = useState(date);
  const [updateDate, setUpdateDate] = useState(formatDate(localDate));

  const isInline = variant === 'inline';

  useEffect(() => {
    setLocalDate(date);
    setUpdateDate(`${format(date, dateFormatFullYear)}`);
  }, [date]);
  const calRef = useRef<DatePicker>(null);

  function onChangeDate(event: any): void {
    const re = /^[0-9/]*$/;

    if (event.target.value === '' || re.test(event.target.value)) {
      setUpdateDate(event.target.value);
    }
  }

  function handleOnBlur(event: any): void {
    const inputValue = event.target.value;

    const newDate = ParseInputDate(inputValue);

    if (isValidDate(newDate)) {
      if (!isSameDay(newDate, localDate)) {
        setLocalDate(newDate);
        if (onDateChange) {
          onDateChange(newDate, type);
        }
      }
    } else {
      setUpdateDate(`${format(localDate, dateFormatFullYear)}`);
    }
  }

  function handleOnKeyDown(event: any): void {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }

  const handleCalendarOnChange = (d: Date) => {
    setLocalDate(d);
    setUpdateDate(formatDate(d));
    if (onDateChange) {
      onDateChange(d, type);
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  const renderDatePicker = () => {
    return (
      <div className={datePickerStyleVariants({ variant, size })}>
        <DatePicker
          portalId={label}
          ref={calRef}
          selected={localDate}
          onChange={handleCalendarOnChange}
          popperPlacement={popperPlacement}
          customInput={<CalendarIconWrapper isFocused={isFocused} size={size || 'xs'} disabled={disabled} />}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
        >
          <div className={styles.buttonsContainer}>
            <Button
              className={styles.buttonContainer}
              label={i18n.t('buttons.clearSelection')}
              size={'sm'}
              tabIndex={-1}
              onClick={() => {
                onResetDate?.();
                calRef?.current?.setOpen(false);
              }}
            />
          </div>
        </DatePicker>
      </div>
    );
  };

  const renderInlineLabel = () => {
    if (!isInline) return null;
    return <label className={inlineLabelStyleVariation({ isFocused, disabled, size })}>{label}</label>;
  };

  return (
    <div className={containerStyleVariation({ variant, size })}>
      <TextInput
        label={isInline ? '' : label}
        margin="none"
        tooltip={toolTipMessage}
        toolTipPosition={toolTipPosition}
        placeholder={dateFormatFullYear}
        size={size}
        value={updateDate}
        className="flex"
        variant={isInline ? 'clean' : 'filled'}
        isUnderlineVisible={!isInline}
        onBlur={(e) => {
          handleOnBlur(e);
          setIsFocused(false);
        }}
        onChange={onChangeDate}
        onKeyDown={handleOnKeyDown}
        onFocus={(e) => {
          e.target.select();
          setIsFocused(true);
        }}
        disabled={disabled}
        LeadingContent={renderInlineLabel}
        TrailingPeerContent={() => renderDatePicker()}
      />
    </div>
  );
};

const styles = {
  buttonContainer: 'flex flex-row flex-1 items-center justify-center cursor-pointer my-2',
  buttonsContainer: 'flex flex-row justify-between py-0 px-1',
};

const inlineLabelStyleVariation = cva('', {
  variants: {
    isFocused: {
      true: 'text-primary-main',
      false: 'text-txt-secondary',
    },
    disabled: {
      true: 'text-action-disabled',
    },
    size: {
      xs: 'px-1 text-2xs',
      sm: 'px-1 text-xs',
      md: 'pl-3 text-sm',
      lg: 'pl-3 text-sm',
    },
  },
});

const datePickerStyleVariants = cva('absolute right-0', {
  variants: {
    variant: {
      default: '-bottom-1',
      inline: '',
    },
    size: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },
  },
  compoundVariants: [
    {
      variant: 'inline',
      size: 'xs',
      className: '-bottom-1',
    },
    {
      variant: 'inline',
      size: 'sm',
      className: '-bottom-1',
    },
    {
      variant: 'inline',
      size: 'md',
      className: '-bottom-0.5',
    },
    {
      variant: 'inline',
      size: 'lg',
      className: 'bottom-0.5',
    },
  ],
});

const containerStyleVariation = cva('', {
  variants: {
    variant: {
      default: '',
      inline: 'bg-paper-card rounded-md flex',
    },
    size: {
      xs: '',
      sm: '',
      md: '',
      lg: '',
    },
  },
  compoundVariants: [
    {
      variant: 'inline',
      size: 'xs',
      className: '',
    },
    {
      variant: 'inline',
      size: 'sm',
      className: '',
    },
    {
      variant: 'inline',
      size: 'md',
      className: 'py-1',
    },
    {
      variant: 'inline',
      size: 'lg',
      className: '',
    },
  ],
});

export default DateInput;
